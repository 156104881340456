import React from 'react';
import '../../../../styles/comurei-form.css';
import { ReactComponent as IAnagrafica } from "../../../../styles/images/svg/id-card.svg";
import { BwmInput } from "../../../shared/form/BwmInput";
import * as actions from "../../../../actions";
import { BwmCheckbox } from "../../../shared/form/BwmCheckbox";
import AutoCompileCustom from "../../../shared/form/AutoCompileCustom";
import radioSel from "../../../../styles/images/radiobutton-selected.png";
import radioUnsel from "../../../../styles/images/radiobutton-unselected.png";
import { PopupError } from "../../../shared/PopupError";
import { ProvinceField } from "../../application-cora/relationships/ProvinceField";
import * as Constants from "../../../../config/Constants";
import '../../../../styles/subject-crs.css';
import { CreateCommunicationModal } from "../communications/CreateCommunicationModal";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import { ReactComponent as ITextFile } from "../../../../styles/images/svgs/regular/file-alt.svg";
import SidContactFormContainer from '../../../shared/form/certificati_firma_comunicazioni_crs_cora/SidContactFormContainer';
import { saveSid, validateSid } from '../../../shared/SidCertsHandler';
import { PopupSuccess } from '../../../shared/PopupSuccess';
import { isEmpty, isNotEmpty } from '../../../shared/Utility';
import { connect } from "react-redux";
import { PopupAlertCommunicationYear } from '../communications/PopupAlertCommunicationYear';
import ComureiSubjectNotPresentAlert from '../../../shared/popups/ComureiSubjectNotPresentAlert';


class CrsForm extends React.Component {

    constructor(props) {
        super(props);
        this.createCommunicationRefData = React.createRef();
        this.nameApplication = Constants.APPLICATION_CRS;
        this.state = {
            subject: {
                id: this.props.subjectItem?.id || null,
                subjectCode: this.props.subjectItem?.subjectCode || "0" + this.props.newSubjectCode,
                fiscalCode: this.props.subjectItem?.fiscalCode || "",
                companyName: this.props.subjectItem?.companyName || "",
                address: this.props.subjectItem?.address || "",
                location: this.props.subjectItem?.location || "",
                province: this.props.subjectItem?.province || "",
                flagOicrTrust: this.props.subjectItem?.flagOicrTrust || false,
                idFiscalOicrTrust: this.props.subjectItem?.idFiscalOicrTrust || '',
                countryCode: this.props.subjectItem?.countryCode || "IT",
                country: this.props.subjectItem?.country || "ITALIA (REPUBBLICA ITALIANA)",
                communicationTypeCode: this.props.subjectItem?.communicationTypeCode || "1",
                referenceYear: this.props.subjectItem?.referenceYear || new Date().getFullYear() - 1,
                lastCommunication: this.props.subjectItem?.lastCommunication || ""
            },
            previousFiscalCode: this.props.subjectItem?.fiscalCode,//copia del codice fiscale pre-modifica.
            coraSubjectSid: {},
            errorSubjectCode: { isValid: true, msg: "" },
            errorFiscalCode: { isValid: true, msg: "" },
            errorCompanyName: { isValid: true, msg: "" },
            errorAddress: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            errorIdFiscalOicrTrust: { isValid: true, msg: "" },
            errorCountry: { isValid: true, msg: "" },
            errorReferenceYear: { isValid: true, msg: "" },
            errorLastCommunication: { isValid: true, msg: "" },
            errorSidEmail: { isValid: true, msg: "" },
            certificateFiles: [],
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: '',
            isCertSidOpened: false,
            showCreateCommunication: true
        }
    }

    updatePecConfiguration = (coraSubjectSid) => {
        this.setState({ ...this.state, coraSubjectSid: coraSubjectSid });
    }

    componentDidMount() {
        let coraSubjectSid = null;
        if (isNotEmpty(this.props.subjectItem?.coraSubjectSid)) {
            coraSubjectSid = this.props.subjectItem?.coraSubjectSid;
            this.setState({
                coraSubjectSid: coraSubjectSid,
            });
        }
        if (isNotEmpty(this.props.auth)) {
            if (isEmpty(coraSubjectSid?.email)) {
                this.setState({ defaultEmail: this.props.auth?.user?.login })
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let coraSubjectSid = null;
        if (isNotEmpty(nextProps.subjectItem?.coraSubjectSid)) {
            coraSubjectSid = nextProps.subjectItem?.coraSubjectSid;
            this.setState({
                coraSubjectSid: coraSubjectSid,
            });
        }

    }

    handlerChangeFiscalCode = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                fiscalCode: e.target.value.toUpperCase()
            },
            errorFiscalCode: { isValid: true, msg: "" },
            previousFiscalCode: this.state.subject?.fiscalCode
        });
    };

    checkSubjectIsPresent = (fiscalCode) => {
        if (this.props.showModal === true) {
            const axiosInstance = axiosService.getInstance();
            axiosInstance.get(`/api/crs/custom/crs-subjects-fiscal-code/${fiscalCode}`)
                .then(res => {
                    if (isNotEmpty(res)) {
                        this.props.handleCloseModal();
                        PopupError(
                            {
                                text: "Soggetto già presente in elenco"
                            }
                        );
                    }
                });
        }
    };

    handlerChangeCompanyName = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                companyName: e.target.value.toUpperCase()
            },
            errorCompanyName: { isValid: true, msg: "" },
        });
    };

    handlerChangeAddress = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                address: e.target.value.toUpperCase()
            },
            errorAddress: { isValid: true, msg: "" },
        });
    };

    handlerChangeOicrTrust = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                flagOicrTrust: e.target.checked,
            }
        });
    };

    handlerChangeFiscalOicrTrust = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                idFiscalOicrTrust: e.target.value.toUpperCase().trim()
            },
            errorIdFiscalOicrTrust: { isValid: true, msg: "" }
        });
    };

    handleFiscalCode = fiscalCode => {
        if (fiscalCode && this.state.previousFiscalCode !== fiscalCode) {

            actions.getBaseRegistryByFiscalCode(fiscalCode).then(
                (baseRegistry) => {
                    if (baseRegistry) {
                        this.setState({

                            subject: {
                                ...this.state.subject,
                                companyName: baseRegistry?.companyName || '',
                                location: baseRegistry?.location?.location || '',
                                province: baseRegistry?.location?.province || ''
                            },
                            errorCompanyName: { isValid: true, msg: "" },
                            errorLocation: { isValid: true, msg: "" },
                            errorProvince: { isValid: true, msg: "" }
                        });
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
            actions.getCoraSubjectSidByFiscalCode(fiscalCode, "crs").then(
                (coraSubjectSid) => {
                    if (coraSubjectSid?.id) {
                        this.setState({
                            coraSubjectSid: coraSubjectSid,
                            errorCertificates: { isValid: true, msg: "" },
                            errorPassword: { isValid: true, msg: "" },
                            errorSidNumber: { isValid: true, msg: "" },
                            errorEmail: { isValid: true, msg: "" }
                        });
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
        }
    };

    handleInputAutocompile = (newLocation, id, suggestionIndex) => {
        this.setState({
            subject: {
                ...this.state.subject,
                location: newLocation.toUpperCase().trim()
            },
            errorLocation: { isValid: true, msg: "" }
        });
        actions.getLocation(newLocation.toUpperCase()).then(
            (loc) => {
                if (loc) {
                    this.setState({

                        subject: {
                            ...this.state.subject,
                            location: loc.location,
                            province: loc.province
                        },
                        errorLocation: { isValid: true, msg: "" },
                        errorProvince: { isValid: true, msg: "" }
                    });
                } else {
                    this.setState({

                        subject: {
                            ...this.state.subject,
                            location: newLocation.toUpperCase()
                        },
                        errorLocation: { isValid: true, msg: "" }
                    });
                }
            })
    };

    handleInputAutocompileCountry = (e) => {
        let newCountry = e.target.value;
        this.setState({
            subject: {
                ...this.state.subject,
                countryCode: newCountry.toUpperCase()
            },
            errorCode: { isValid: true, msg: "" }
        });
        let countriesFilter = this.props.countries.filter(country => {
            return country.name === newCountry
        })
        if (countriesFilter.length === 1) {
            this.setState({
                subject: {
                    ...this.state.subject,
                    countryCode: countriesFilter[0].name,
                    country: countriesFilter[0].key
                }
            });
        }
        if (countriesFilter.length === 0) {
            this.setState({
                subject: {
                    ...this.state.subject,
                    countryCode: newCountry.toUpperCase(),
                    country: ''
                }
            })
        }
    };
    setStateFunction = (value) => {
        this.setState(value)
    }

    handleProvinceChange = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                province: e.target.value.toUpperCase().trim()
            },
            errorProvince: { isValid: true, msg: "" }
        });
    };

    handleCommunicationTypeClick = (e) => {
        this.setState({
            showCreateCommunication: false,
            subject: {
                ...this.state.subject,
                communicationTypeCode: e.target.value
            }
        });
    };

    handlerChangeReferenceYear = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                referenceYear: e.target.value
            }
        });
    };

    handlerChangeLastCommunication = (e) => {
        this.setState({
            subject: {
                ...this.state.subject,
                lastCommunication: e.target.value.toUpperCase()
            }
        });
    };

    handleValidation = () => {
        let isValid = true;
        if (!this.state.subject.companyName) {
            this.setState({
                errorCompanyName: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.fiscalCode) {
            this.setState({
                errorFiscalCode: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.address) {
            this.setState({
                errorAddress: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.location) {
            this.setState({
                errorLocation: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.province) {
            this.setState({
                errorProvince: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.state.subject.referenceYear) {
            this.setState({
                errorReferenceYear: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (this.state.subject.flagOicrTrust && !this.state.subject.idFiscalOicrTrust) {
            this.setState({
                errorIdFiscalOicrTrust: { isValid: false, msg: "Campo obbligatorio" }
            });
            isValid = false;
        }

        if (!this.configurationSidValidation()) {
            isValid = false;
        }

        return isValid;
    };

    handleFormSubmit = (event, sendComm) => {
        event.preventDefault();
        if (this.handleValidation()) {
            this.addCrsSubject(this.state.subject, sendComm);
        }
    };

    updateAll = (sendComm) => {
        if (!this.handleValidation()) return;
        if (sendComm === true) {
            //questa casistica non può più avverarsi
            const lastYear = new Date().getFullYear() - 1;
            if (this.state.subject.referenceYear < lastYear) {
                PopupAlertCommunicationYear(this.state.subject.referenceYear, lastYear
                    , () => this.saveCrsSubject(sendComm)
                    , () => {
                        this.setState((prevState) => ({
                            subject: {
                                ...prevState.subject,
                                referenceYear: lastYear
                            }
                        }), () => this.saveCrsSubject(sendComm));
                    })
                return;
            }
        }
        this.saveCrsSubject(sendComm);


    };

    updateAndClose = (sendComm) => {
        this.updateAll(sendComm);
        if (this.handleValidation()) {
            this.props.handleCloseModal();
        }

    }

    saveCrsSubject = (sendComm) => {

        saveSid(this.state.certificateFiles
            , this.state.coraSubjectSid
            , this.state.subject?.fiscalCode
            , this.state.coraSubjectSid?.application || "crs"
            , (res) => this.addCrsSubject(this.state.subject, sendComm, res)
            , (errors) => {
                PopupError({ ...this.props, text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR })
                this.resetError();
                this.resetFields();
            })

    }

    configurationSidValidation = () => {

        return validateSid(
            this.state.coraSubjectSid.sidNumber
            , this.state.coraSubjectSid.email
            , (state) => this.setState(state)
            , this.state.errorSidNumber
            , this.state.errorEmail
            , "errorSidNumber"
            , "errorEmail"
        )

    };

    handleChangeStartDate = (date) => {
        if (date) {
            this.state.coraSubjectSid.startDate = date;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.startDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeExpirationDate = (val) => {
        if (val) {
            this.state.coraSubjectSid.expirationDate = val;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.expirationDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeEncryptionBits = (val) => {
        this.state.coraSubjectSid.encryptionBits = val;
        this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
    }

    /* Validation onChange */
    handleChangeSid = (val, error, errorMsg) => {
        this.state.errorInConfigSid = '';
        let valueInput = val.target.value;
        if (isEmpty(valueInput)) {
            error.isValid = false;
            error.msg = errorMsg;
        } else {
            error.isValid = true;
            error.msg = '';
        }
        let newValue = valueInput.toUpperCase().trim();
        if (val.target.name === "password") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    password: newValue,
                },
                error
            }));
        } else if (val.target.name === "sidNumber") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    id: null,
                    sidNumber: newValue,
                },
                error
            }));
        } else if (val.target.name === "email") {
            this.setState(prevState => ({
                coraSubjectSid: {
                    ...prevState.coraSubjectSid,
                    email: newValue,
                },
                error
            }));
        }


    };


    handlerChangeFlagPec = (e) => {
        let checked = e.target.checked
        this.setState(prevState => ({

            coraSubjectSid: {
                ...prevState.coraSubjectSid,
                flagPec: checked
            }
        }));
    }

    addCrsSubject = (newSubject, sendComm, sid) => {
        const shouldVerifyOnComurei = isEmpty(newSubject.id) || (this.state.previousFiscalCode !== newSubject.fiscalCode);
        newSubject.coraSubjectSid = sid;
        actions.addCrsSubject(newSubject).then(
            (response) => {
                this.setState({
                    subject: response
                });

                if (shouldVerifyOnComurei) {
                    actions.verifyCrsSubjectOnComurei(newSubject.fiscalCode).then(
                        (res) => {
                            if (res === false) {
                                ComureiSubjectNotPresentAlert({ fiscalCode: newSubject.fiscalCode, denomination: newSubject.companyName });
                            } else {
                                PopupSuccess({ text: "Soggetto salvato correttamente" })
                            }
                        },
                        (errors) => {
                            console.log(errors);
                            PopupSuccess({ text: "Soggetto salvato correttamente" })
                        }
                    )
                } else if (sendComm) {
                    if (document.getElementById("subject-modal")) {
                        document.getElementById("subject-modal").style.display = "none";
                    }
                    this.createCommunicationRefData.current.clickButtonSend();
                } else {
                    PopupSuccess({ text: "Soggetto salvato correttamente" })
                }
                this.props.getSubjects()
            },
            (errors) => {

                if (errors.status === 511) {
                    PopupError(
                        {
                            text: errors.status === 511 ?
                                'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'
                                :
                                Constants.APPLICATION_GENERIC_ERROR
                        }
                    );
                } else {
                    PopupError({ text: errors?.data?.title || Constants.APPLICATION_GENERIC_ERROR })
                }
            }
        );
    };

    setCertificateFilesCustom = (val) => {
        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        this.state.isCertSidOpened = true;

        if (isNotEmpty(val) && this.state.certificateFiles !== val) {
            this.setState({ certificateFiles: val })
        }
    };



    resetError = () => {
        this.setState({
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: ''
        });
    };

    resetFields = () => {
        this.setState({
            coraSubjectSid: {}
        })
    };

    render() {
        return (
            <div className="row-detail bg-blue pt-4 pe-3 pb-4 ps-3">
                <div className="row crs" id={`anagrafica-${this.props.subject?.id || 0}`}>
                    <div className={"col-6"}>
                        <div className="soggetto-crs big">
                            <div className='row'>
                                <h5 className="ms-4 mb-4"><IAnagrafica fill="#000" width="25" />Anagrafica</h5>
                            </div>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <label>Codice</label>
                                    <input name='subjectCode'
                                        className='form-control text-end'
                                        value={this.state.subject.subjectCode}
                                        maxLength="16"
                                        disabled="disabled"
                                    />
                                </div>
                                <div className='col-md-9'>
                                    <BwmInput name='fiscalCode'
                                        label={!this.state.subject.flagOicrTrust ? 'Codice Fiscale' : 'C. Fiscale del Comunicante'}
                                        className='form-control'
                                        value={this.state.subject.fiscalCode}
                                        onChange={e => this.handlerChangeFiscalCode(e)}
                                        error={this.state.errorFiscalCode}
                                        maxLength="11"
                                        onBlur={e => this.handleFiscalCode(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-11'>
                                    <BwmInput name='companyName'
                                        label={!this.state.subject.flagOicrTrust ? 'Ragione Sociale' : 'Denominazione OICR o TRUST'}
                                        className='form-control'
                                        value={this.state.subject.companyName}
                                        onChange={e => this.handlerChangeCompanyName(e)}
                                        error={this.state.errorCompanyName}
                                        maxLength="70"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-11'>
                                    <BwmInput name='address'
                                        label='Indirizzo'
                                        className='form-control'
                                        value={this.state.subject.address}
                                        onChange={e => this.handlerChangeAddress(e)}
                                        error={this.state.errorAddress}
                                        maxLength="100"
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <BwmCheckbox
                                        name={`flagOicrTrust`}
                                        label='OICR o TRUST'
                                        className='form-control'
                                        value={this.state.subject.flagOicrTrust}
                                        onClick={(e) => this.handlerChangeOicrTrust(e)}
                                    />
                                </div>
                                <div className='col-md-5'>
                                    <AutoCompileCustom
                                        label='Sede Legale (Località)'
                                        id="location"
                                        handleInputAutocompile={e => this.handleInputAutocompile(e)}
                                        filtro={this.props.locations}
                                        value={this.state.subject.location || ""}
                                        error={this.state.errorLocation}
                                    />
                                </div>
                                <div className="col-md-2 col-lg-2">
                                    <ProvinceField province={this.state.subject.province || ""}
                                        onChange={e => this.handleProvinceChange(e)}
                                        error={this.state.errorProvince} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4 oicrContainer'>
                                    <div className={`oicr ${this.state.subject.flagOicrTrust && 'show'}`}>
                                        <span>Id Fiscal OICR/TRUST</span>
                                        <div className='form-group' data-tip="ID OICR senza p. giuridica rilasciato da Banca d'Italia (5 caratteri) oppure ID Fiscale TRUST (16 caratteri)">
                                            <div className='input-group'>
                                                <input
                                                    className="form-control"
                                                    name="oicrFiscal"
                                                    value={this.state.subject.idFiscalOicrTrust}
                                                    type="text"
                                                    onChange={(e) => this.handlerChangeFiscalOicrTrust(e)}
                                                />
                                            </div>
                                        </div>
                                        {!this.state.errorIdFiscalOicrTrust.isValid &&
                                            <div className="text-danger">{this.state.errorIdFiscalOicrTrust.msg}</div>
                                        }
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    <BwmSelect
                                        options={this.props.countries}
                                        name='country'
                                        id="country"
                                        label='Paese'
                                        className='form-control'
                                        onChange={e => this.handleInputAutocompileCountry(e)}
                                        value={this.state.subject.countryCode || ""}
                                        error={this.state.errorCountry}
                                        showCode={true}
                                        nameKey={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-6"}>
                        <div className={"row"}>
                            <div className="soggetto-crs small">
                                <div className="row">
                                    <h5 className="ms-4 mb-4"><IAnagrafica fill="#000" width="25" />Tipo Comunicazione</h5>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="input-group flex-column h-100 justify-content-around">
                                            <div className="inputRadio me-3">
                                                <input hidden={true} className="form-check-input"
                                                    type="radio"
                                                    name={`communicationTypeCode`} checked={this.state.subject.communicationTypeCode === '1'}
                                                    id={`ct1`} value={'1'}
                                                    onChange={this.handleCommunicationTypeClick} />
                                                <label className="form-check-label" htmlFor={`ct1`}>
                                                    <img width="20" className="me-2"
                                                        src={this.state.subject.communicationTypeCode === '1' ? radioSel : radioUnsel} />
                                                    NUOVI DATI
                                                </label>
                                            </div>
                                            <div className="inputRadio">
                                                <input hidden={true} className="form-check-input"
                                                    type="radio"
                                                    name={`communicationTypeCode`}
                                                    checked={this.state.subject.communicationTypeCode === '2'}
                                                    id={`ct2`} value={'2'}
                                                    disabled={true}
                                                    onChange={this.handleCommunicationTypeClick} />
                                                <label className="form-check-label" htmlFor={`ct2`}>
                                                    <img width="20" className="me-2"
                                                        src={this.state.subject.communicationTypeCode === '2' ? radioSel : radioUnsel} />SOSTITUTIVA
                                                </label>
                                            </div>
                                            <div className="inputRadio">
                                                <input hidden={true} className="form-check-input"
                                                    type="radio"
                                                    name={`communicationTypeCode`}
                                                    checked={this.state.subject.communicationTypeCode === '3'}
                                                    id={`ct3`} value={'3'}
                                                    disabled={true}
                                                    onChange={this.handleCommunicationTypeClick} />
                                                <label className="form-check-label" htmlFor={`ct3`}>
                                                    <img width="20" className="me-2"
                                                        src={this.state.subject.communicationTypeCode === '3' ? radioSel : radioUnsel} />ANNULLAMENTO
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="form-group modal-80w">
                                            <BwmInput name='refYear'
                                                label='Anno di riferimento'
                                                className='form-control'
                                                value={this.state.subject.referenceYear}
                                                onChange={e => this.handlerChangeReferenceYear(e)}
                                                error={this.state.errorReferenceYear}
                                                maxLength="4"
                                            />
                                        </div>
                                        <div className='form-group modal-80w'>
                                            <BwmInput name='lastComm'
                                                label='Ultima comunicazione'
                                                className='form-control borde-bottom-0'
                                                value={this.state.subject.lastCommunication}
                                                maxLength="100"
                                                readOnly={true}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="soggetto-crs auto">
                                <SidContactFormContainer  {...this} nameApplication={this.nameApplication} />
                                {
                                    !this.props.showModal &&
                                    <div className="panel-info align-items-end">
                                        <h5>Codice soggetto: {this.state.subject?.id}</h5>
                                        {
                                            this.state.coraSubjectSid &&
                                            <h5>Codice SID: {this.state.coraSubjectSid?.id}</h5>

                                        }
                                    </div>
                                }
                            </div>
                        </div>

                    </div>

                </div>
                <div
                    className="form-row mt-2 form-relationship-button justify-content-between align-item-center">
                    <button
                        className="btn btn-outline-primary btn-empty px-4 btn-sm"
                        onClick={this.props.handleCloseModal}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                    </button>
                    <CreateCommunicationModal
                        {...this.props}
                        ref={this.createCommunicationRefData}
                        subjectId={this.state.subject?.id}
                        emailAddress={this.state.subject?.coraSubjectSid?.email}
                        generateEmpty={true}
                        classCustom={"display-none"}
                        closeSubjectModal={this.props.handleCloseModal}
                        subject={this.state.subject}
                    />
                    <button onClick={() => this.updateAll(true)}
                        disabled={this.props.disableCreateCommunication}
                        className="btn btn-primary btn-new-rel px-5"><ITextFile className={"padding-bottom-4px svg"} width="13" fill={`#FFFFFF`} />&nbsp; CREA COMUNICAZIONE VUOTA
                    </button>
                    <button onClick={() => this.updateAndClose()}
                        className="btn btn-primary btn-new-rel px-5"><ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                    </button>
                </div>
            </div>
        )
    }

}
function mapStateToProps(state) {

    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(CrsForm);
