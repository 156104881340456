import React from 'react';
import Modal from "react-bootstrap/Modal";
import { PageSpinner } from "../../../shared/spinner/PageSpinner";
import { BtnEmpty } from "../../../shared/BtnEmpty";
import Row from "react-bootstrap/Row";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as IExport } from "../../../../styles/images/svgs/regular/file-export.svg";
import { BwmInput } from "../../../shared/form/BwmInput";
import { PopupError } from '../../../shared/PopupError';
import { downloadFile, emailRegexp, isEmpty, isNotEmpty } from '../../../shared/Utility';
import { exportCoraRelationshipsSync, searchCoraRelationships } from '../../../../actions';
import { baseSearchObject } from '../../../shared/tables/TableUtils';
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox';
import { Col } from 'react-bootstrap';

export class CoraSubjectsExportModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            loading: false,
            responseModal: false,
            responseKo: "",
            responseOk: "",
            checkedSubjects: this.props.checkedSubjects,
            emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login,
            errorEmail: { isValid: true, msg: "" },
            requestTxt: false,
            requestCsv: false,
            requestCompleteExport: true,
            currentSubject: this.props.checkedSubjects[0],
            currentIndex: 0,
        }
    }



    //TODO: aprire un popup per soggetto selezionato
    //indicare nome soggetto nel titolo
    // permettere di selezionare invio per email e/o download sincrono
    //pulsante conferma esegue le modalità selezionate
    //modificare info su modale al susseguirsi di export

    componentDidMount() {
        this.countTotalRelationships(this.props.checkedSubjects[0]);
    }

    countTotalRelationships = (subject) => {
        searchCoraRelationships({ ...baseSearchObject, parentId: subject.id, pageSize: 1 })
            .then((res) => {
                this.setState({ totalRelationships: res.totalElements });
            }
            )
    }

    closeModal = () => {
        this.props.onClose();
    };

    unmount = () => {
        this.setState({
            showModal: false
            , emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login
            , displayWarning: true
        });
    }

    closeResponseModal = () => {
        this.props.onClose();
        //this.setState({ responseModal: false });

        //this.setState({emailAddress: this.props.checkedSubjects[0]?.customer?.user?.login});
    };



    handlerEmail = (e) => {
        this.setState({ errorEmail: { isValid: true, msg: "" }, emailAddress: e.target.value });
    };


    handlerValidation = () => {

        if (isEmpty(this.state.emailAddress)) {
            this.setState({ errorEmail: { isValid: false, msg: "Campo obbligatorio" } });
            return false;
        }
        if (!emailRegexp.test(this.state.emailAddress)) {
            this.setState({ errorEmail: { isValid: false, msg: "Email non formalmente corretta" } });
            return false;
        }
        return true;
    };

    onClickExport = () => {
        if (this.handlerValidation()) {
            this.setState({ loading: true })
            let formats = [];
            if (this.state.requestCsv) {
                formats.push("CSV");
            }
            if (this.state.requestTxt) {
                formats.push("TXT");
            }
            const subject = this.state.currentSubject;
            let email = '';
            if (this.state.requestCompleteExport) {
                email = this.state.emailAddress
            }
            exportCoraRelationshipsSync(subject.id, formats, email)
                .then((res) => {
                    if (res && (this.state.requestTxt || this.state.requestCsv)) {
                        downloadFile(res.data, `EXPORT_${subject.completeName}.zip`);
                    }
                    //avanzare il soggetto corrente
                    this.advanceSubjectOrCloseModal();
                }, (err) => {
                    PopupError({ text: "Si è verificato un errore durante l'esportazione dei dati." })
                })
                .then(() => this.setState({ loading: false }));

        }
    }



    advanceSubjectOrCloseModal = () => {
        const advancedIndex = this.state.currentIndex + 1;
        const advancedSubject = this.state.checkedSubjects[advancedIndex];
        if (isNotEmpty(advancedSubject)) {
            this.setState({
                currentSubject: advancedSubject,
                currentIndex: advancedIndex
            }, () => { this.countTotalRelationships(advancedSubject) });

        } else {
            this.closeModal()
        }
    }

    render() {
        const currentSubject = this.state.currentSubject;
        let email =  this.state.emailAddress;
        return (
            <React.Fragment>
                {this.state.loading ? <PageSpinner /> : ''}

                <Modal
                    backdrop="static"
                    show={this.state.showModal}
                    onHide={this.closeModal}
                    dialogClassName="modal-60w"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div className="bar"></div>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2>EXPORT <strong>{currentSubject.completeName}</strong></h2>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="row-detail comurei bg-blue pt-2 pe-3 pb-2 ps-3  d-flex radius">
                            <div className="tab-content col-md-12 col-lg-12">
                                <div className="form-row">
                                    <div className="col-md-8 col-lg-8 subDetailComurei">
                                        <Row>
                                            <div className="input-group mt-2">
                                                <BwmCheckbox
                                                    name="completo"
                                                    label={"Scarica TUTTI I DATI (ricezione via email)"}
                                                    value={this.state.requestCompleteExport}
                                                    onClick={(e) => this.setState({ requestCompleteExport: !this.state.requestCompleteExport })}
                                                    className='form-control'
                                                />
                                            </div>
                                        </Row>
                                        <div className="row">
                                            <div className="col-md-11 col-lg-11">
                                                <BwmInput
                                                    id="emailAddress"
                                                    type="email"
                                                    className="form-control"
                                                    label="Indirizzo email a cui inviare il file di export"
                                                    value={email}
                                                    maxLength="100"
                                                    onChange={(e) => this.handlerEmail(e)}
                                                    error={this.state.errorEmail}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <h5 className='mt-4'><strong>DOWNLOAD IMMEDIATO dei soli rapporti</strong></h5>
                            (Rilevati: {this.state.totalRelationships}. L'esecuzione potrebbe richiedere qualche minuto)
                            <Row>
                                <Col lg={6} >
                                    <div className="input-group mt-2">
                                        <BwmCheckbox
                                            name="TXT"
                                            label={"In formato TXT"}
                                            value={this.state.requestTxt}
                                            onClick={(e) => this.setState({ requestTxt: !this.state.requestTxt })}
                                            className='form-control'
                                        />
                                    </div>
                                    <div className="input-group mt-2">
                                        <BwmCheckbox
                                            name="CSV"
                                            label={"In formato CSV (leggibile con Excel)"}
                                            value={this.state.requestCsv}
                                            onClick={(e) => this.setState({ requestCsv: !this.state.requestCsv })}
                                            className='form-control'
                                        />
                                    </div>

                                </Col>
                            </Row>
                        </div>
                        <div className="d-flex justify-content-between align-content-end mt-5">
                            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={this.closeModal} />
                            <button
                                onClick={this.onClickExport}
                                type="button"
                                className="btn btn-new-rel px-5"
                                disabled={!this.state.requestCompleteExport && !this.state.requestCsv && !this.state.requestTxt}
                            >
                                CONFERMA
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                
            </React.Fragment>
        )
    }
}
