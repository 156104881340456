import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { BwmInput } from "../shared/form/BwmInput";
import { emailRegexp, isEmpty } from "../shared/Utility";
import { BtnEmpty } from "../shared/BtnEmpty";
import { BtnFill } from "../shared/BtnFill";
import { adminCreateUser } from "../../actions";
import { PopupError } from "../shared/PopupError";
import { APPLICATION_DAC7, APPLICATION_GENERIC_ERROR } from "../../config/Constants";
import { InfoBox } from "../shared/InfoBox";

export function AdminNewCustomerModal({
    onClose = () => { }
    , onConfirm = (userDto) => { } }) {


    const [userDto, setUserDto] = useState({ companyName: "DEMO" });
    const [emailError, setEmailError] = useState({ isValid: true })
    const [passwordError, setPasswordError] = useState({ isValid: true })

    const validate = () => {
        let valid = true;
        if (isEmpty(userDto?.email) || !emailRegexp.test(userDto?.email)) {
            setEmailError({ isValid: false, msg: "Email non valida" });
            valid = false;
        } else {
            setEmailError({ isValid: true });
        }
        if (isEmpty(userDto?.password) || userDto?.password.length < 4 || userDto?.password.length > 100) {
            setPasswordError({ isValid: false, msg: "Password non valida" });
            valid = false;
        } else {
            setPasswordError({ isValid: true });
        }
        return valid;
    }

    const onClickConfirm = () => {
        if (!validate()) {
            return;
        }
        adminCreateUser(userDto).then((res) => {
            onConfirm(res);
        }, (err) => {
            PopupError({ text: APPLICATION_GENERIC_ERROR })
        })

    }

    return <Modal
        show={true}
        onHide={onClose}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-content-sm"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <h2>Aggiungi nuovo utente</h2>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-blue">
            <Row>
                <Col lg={7}>
                    <div className="row p-2 bg-blue  m-auto">
                        <div className='col-6'>
                            <BwmInput
                                label={"Email*"}
                                className="form-control"
                                value={userDto?.email}
                                onChange={(e) => setUserDto({ ...userDto, email: e.target.value.trim() })}
                                error={emailError}
                            /></div>
                        <div className='col-6'>
                            <BwmInput
                                label={"Password*"}
                                className="form-control"
                                value={userDto?.password}
                                maxLength={100}
                                onChange={(e) => setUserDto({ ...userDto, password: e.target.value })}
                                error={passwordError}
                            /></div>
                    </div>
                    <div className="row p-2 bg-blue  m-auto">
                        <div className='col-6'>
                            <BwmInput
                                label={"Nome"}
                                className="form-control"
                                value={userDto?.firstName}
                                onChange={(e) => setUserDto({ ...userDto, firstName: e.target.value.trim() })}
                            /></div>
                        <div className='col-6'>
                            <BwmInput
                                label={"Cognome"}
                                className="form-control"
                                value={userDto?.lastName}
                                onChange={(e) => setUserDto({ ...userDto, lastName: e.target.value.trim() })}
                            /></div>
                    </div>
                </Col>
                <Col lg={5}>
                <InfoBox
                    simplePointsArr={[
                        {
                            title:"Come funziona",
                            text:`Se inesistente, viene creato un nuovo cliente con la password specificata e una licenza comurei.
                             Se il cliente esiste, ma non è visibile in lista, viene aggiunta solo una licenza comurei, ma nome, cognome e password rimarranno invariati.`
                        }
                    ]}
                />
                </Col>
            </Row>

        </Modal.Body>
        <Modal.Footer>
            <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={onClose} />
            <BtnFill text="CREA E CONTINUA" classCustom="float-end text-end"
                handlerOnClick={() => onClickConfirm()} />
        </Modal.Footer>
    </Modal>
}