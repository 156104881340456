import React from "react";
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import { CoraSubjectForm } from "./CoraSubjectForm";
import axiosService from "../../../../services/axios-service";
import { ReactComponent as ISave } from "../../../../styles/images/svgs/regular/save.svg";
import { ReactComponent as IUndo } from "../../../../styles/images/svgs/regular/times-circle.svg";
import * as Constants from "../../../../config/Constants";
import { isEmpty,isNotEmpty } from "../../../shared/Utility";
import { saveSid, validateSid } from "../../../shared/SidCertsHandler";



export class CoraSubjectRowDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            checked: false,
            subjectSaved: false,
            subject: '',
            fiscalCode: '',
            previousFiscalCode:"",
            companyName: '',
            location: '',
            province: '',
            flagPec: false,
            errorCompanyName: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            coraSubjectSid: {},
            certificateFiles: [],
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorFlagPec: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: '',
            isCertSidOpened: false,
            defaultEmail: "",
        }
    }

    componentDidMount() {
        this.getCurrentUser();
        if (isNotEmpty(this.props.subject)) {
            this.setState({
                subjectSaved: true,
                subject: this.props.subject,
                fiscalCode: this.props.subject.fiscalCode,
                previousFiscalCode: this.props.subject.fiscalCode,
                companyName: this.props.subject.companyName,
                location: this.props.subject.location,
                province: this.props.subject.province,
                personType: this.props.subject.personType,
                gender:this.props.subject.gender,
                firstName:this.props.subject.firstName,
                lastName:this.props.subject.lastName,
                birthDate:this.props.subject.birthDate && new Date(this.props.subject.birthDate)
            })
        }
        if (isNotEmpty(this.props.subject?.coraSubjectSid)) {
            this.setState({
                coraSubjectSid: this.props.subject.coraSubjectSid
            });
        }
    }

  
    handleSelectLocation = (location) => {
        this.setState({
            location: location.toUpperCase().trim(),
            errorLocation: { isValid: true, msg: "" }
        });

        if (location === '') {
            this.setState({ province: '' })
        } else {
            actions.getLocation(location.toUpperCase().trim()).then(
                (locOBJ) => {
                    if(locOBJ){
                        this.setState({
                            province: locOBJ.province,
                            errorProvince: { isValid: true, msg: "" }
                        })
                    }
                });
        }
    };

    getCurrentUser = () => {
        const axiosInstance = axiosService.getInstance();
        axiosInstance.get(`/api/common/users/current`)
            .then(res => {
                if (isNotEmpty(res)) {
                    this.setState({
                        defaultEmail: this.props.subject?.customer?.customerApplications[0]?.customer?.user?.login
                    })
                }
            });
    };

    handleChange = (event) => {
        if (event) this.state.errorInSubject = '';
        if (event._dispatchInstances.memoizedProps.type === "companyName") {
            this.setState({
                companyName: event.target.value.toUpperCase(),
                errorCompanyName: { isValid: true, msg: "" }
            })
        }
        if (event._dispatchInstances.memoizedProps.type === "provincia") {
            this.setState({
                province: event.target.value.toUpperCase().trim(),
                errorProvince: { isValid: true, msg: "" }
            })
        }
    };

    formValidation = () => {
        let isValid = true;
        if(this.state.personType === Constants.PNF){
            if (this.state.companyName === '') {
                this.setState({ errorCompanyName: { isValid: false, msg: "Campo obbligatorio" } });
                isValid = false;
            }
        }
        if (this.state.location === '' || this.state.location === undefined) {
            this.setState({ errorLocation: { isValid: false, msg: "Campo obbligatorio" } });
            isValid = false;
        }
        if (this.state.province === '' || this.state.province === undefined) {
            this.setState({ errorProvince: { isValid: false, msg: "Campo obbligatorio" } });
            isValid = false;
        }

        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        if (!this.state.isCertSidOpened) return isValid;

        if (!this.configurationSidValidation()) {
            isValid = false;
        }
        return isValid;
    };

    handlerChangeFiscalCode = (e) => {
        this.setState({
            fiscalCode: e.target.value.toUpperCase().trim()
        });
    };

    handleFiscalCode = fiscalCode => {
        if(fiscalCode && fiscalCode !== this.state.previousFiscalCode){
            actions.getBaseRegistryByFiscalCode(fiscalCode).then(
                (baseRegistry) => {
                    if (baseRegistry) {
                        this.setState({
                            companyName: baseRegistry?.companyName || '',
                            location: baseRegistry?.location?.location || '',
                            province: baseRegistry?.location?.province || ''
                        });
                    }
                },
                (errors) => {
                    console.log(errors);
                }
            )
        }
        
    };

    updateCoraSubject = (sid) => {
        var newSubject = this.state.subject;
        newSubject.fiscalCode = this.state.fiscalCode;
        newSubject.companyName = this.state.companyName;
        newSubject.personType = this.state.personType;
        newSubject.firstName = this.state.firstName;
        newSubject.lastName = this.state.lastName;
        newSubject.gender = this.state.gender;
        newSubject.birthDate = this.state.birthDate;
        newSubject.location = this.state.location;
        newSubject.province = this.state.province;
        newSubject.modifyDate = new Date();
        newSubject.coraSubjectSid = sid;
        this.props.updateCoraSubject(newSubject, this.state.previousFiscalCode);
    };

    handlePersonTypeClick = (event) => {
        const type = event.target.value;
        if (type === Constants.PF) {
            this.setState({ personType: event.target.value, gender: 'FEMALE', companyName:  undefined })
        } else {
            this.setState({
                personType: event.target.value,
                gender: undefined,
                firstName: undefined,
                lastName: undefined,
                birthDate: undefined
            });
        }
    }

    handlerChangeGender = (e) => {
        this.setState({ gender: e.target.value })
    }

    handlerChangeBirthDate = (date) => {
        this.setState({ birthDate: new Date(date) })
    }

    handlerChangeFirstName = (e) => {
        this.setState({ firstName: e.target.value?.toUpperCase() })
    }

    handlerChangeLastName = (e) => {
        this.setState({ lastName: e.target.value?.toUpperCase()})
    }
    /**
     * Questa funzione ha il compito di fare update dei file caricari dall'utente.
     * E successivamente chiudere la pagina
     */
    confermaChiusura=()=>{
        if(this.updateAll()){
            this.closeDetails();
        }
    }

    updateAll = () => {
        if (!this.formValidation()) {
            return false
        };

        saveSid(this.state.certificateFiles
            , this.state.coraSubjectSid
            , this.state.fiscalCode
            , this.state.coraSubjectSid?.application || "cora"
            , (res) => this.updateCoraSubject(res)
            , (errors) => { PopupError({ ...this.props, text: errors.detail ? errors.detail : Constants.APPLICATION_GENERIC_ERROR })
                            return false})
        
            return true;
    }

    configurationSidValidation = () => {
        return validateSid(this.state.coraSubjectSid.sidNumber
            , this.state.coraSubjectSid.email || this.state.defaultEmail
            , (state) => this.setState(state)
            , this.state.errorSidNumber
            , this.state.errorEmail     
            , "errorSidNumber"
            , "errorEmail");
    }

    setStateFunction = (value)=>{
        this.setState(value)
    }


    handlerChangeFlagPec = (e) => {
        let checked = e.target.checked
        this.setState(prevState => ({

            coraSubjectSid: {
                ...prevState.coraSubjectSid,
                flagPec: checked
            }
        }));
    }

    /* Validation onChange */
    handleChangeSid = (val, error, errorMsg) => {
        this.state.errorInConfigSid = '';
        let value = val.target.value;
        let name = val.target.name;
        //FIXME: settare l'errore in questo modo non è utile
        if (isEmpty(value)) {
            error.isValid = false;
            error.msg = errorMsg;
            this.setState(error);
        } else {
            error.isValid = true;
            error.msg = '';
            this.setState(error);
        }

        if (name === "password") {

            this.setState({
                coraSubjectSid: {
                    ...this.state.coraSubjectSid,
                    password: value.toUpperCase().trim()
                }
               
            })

            //this.state.coraSubjectSid.password = value.toUpperCase().trim();
        }
        if (name === "sidNumber") {

            if(this.checkSid(value.toUpperCase().trim())){
                this.setState({
                    coraSubjectSid: {
                        ...this.state.coraSubjectSid,
                        id: null,
                        sidNumber: value.toUpperCase().trim()
                    }
                   
                })        
            } else {
                PopupError({text:`Numero SID: ${value}<br> Rilevato per più di un soggetto: registrazione impossibile.`});
            }
        }
        if (name === "email") {
            this.state.coraSubjectSid.email = value.toUpperCase().trim();
        }

     
    }

    //il sid deve essere univoco nella lista soggetti
    checkSid(sidNumber){
        const allSubjects= this.props.allSubjects || [];
        for(const subject of allSubjects){
            const sid= subject.coraSubjectSid?.sidNumber;
            if(subject.id != this.state.subject.id && sid === sidNumber){
                return false
            } 
        }
        return true;
    }

    updatePecConfiguration=(coraSubjectSid)=>{
        this.setState({ ...this.state, coraSubjectSid: coraSubjectSid });
    }

    handleChangeStartDate = (date) => {
        if (date) {
            this.state.coraSubjectSid.startDate = date;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.startDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeExpirationDate = (val) => {
        if (val) {
            this.state.coraSubjectSid.expirationDate = val;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        } else {
            this.state.coraSubjectSid.expirationDate = null;
            this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
        }
    }

    handleChangeEncryptionBits = (val) => {
        this.state.coraSubjectSid.encryptionBits = val;
        this.setState({ ...this.state, coraSubjectSid: this.state.coraSubjectSid });
    }
    
    setCertificateFilesCustom = (val) => {
        /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
        this.state.isCertSidOpened = true;

        if (isNotEmpty(val) && this.state.certificateFiles !== val){
            this.setState({certificateFiles:val})
        }
    }


    resetError = () => {
        this.setState({
            errorCompanyName: { isValid: true, msg: "" },
            errorLocation: { isValid: true, msg: "" },
            errorProvince: { isValid: true, msg: "" },
            errorCertificates: { isValid: true, msg: "" },
            errorPassword: { isValid: true, msg: "" },
            errorSidNumber: { isValid: true, msg: "" },
            errorEmail: { isValid: true, msg: "" },
            errorFlagPec: { isValid: true, msg: "" },
            errorInSubject: '',
            errorInConfigSid: ''
        });
    }


    closeDetails = () => {
        this.props.openDetails();
    }


    render() {
        const subject = this.props.subject;
        const idDetails = `subjectRowDetails-${subject.id}`;
        return (
            <div id={idDetails} className='bg-blue '>
                <CoraSubjectForm {...this} key={idDetails} modal={false} />

                <div className="form-row mt-2 form-relationship-button">
                    <button
                        className="btn btn-empty px-4 btn-sm"
                        onClick={this.closeDetails}><IUndo className={"padding-bottom-4px svg"} width="16" fill={"#128186"} />&nbsp; ANNULLA
                    </button>

                    <button type="button" onClick={this.confermaChiusura}
                        disabled={this.props.licenseExpired}
                        className="btn  btn-new-rel px-5"><ISave className={"padding-bottom-4px svg"} width="16" fill={"#FFFFFF"} />&nbsp; CONFERMA
                    </button>
                </div>
            </div>
        )
    }
}

