import checkbox from "../../../styles/images/checkbox-inactive.png";
import checkboxSel from "../../../styles/images/checkbox-selected.png";

export const BwmCheckbox = ({
    input,
    label,
    extraLabelClass,
    onClick,
    name,
    value,
    error,
    disabled
}) => (
    <div>
        <div className='input-group'>
            { value &&
                <input hidden={true} {...input} type="checkbox" className="form-check-input" name={name} id={name} onChange={onClick} checked disabled={disabled}/>
            }
            {   !value &&
                <input hidden={true} {...input} type="checkbox" className="form-check-input" name={name} id={name} onChange={onClick} disabled={disabled}/>
            }
            <label className={`form-check-label`} htmlFor={name}>
                <img width="20" className="me-1" src={value? checkboxSel : checkbox}/>
                <span className={`${value && 'fw-bold'} ${extraLabelClass ? extraLabelClass : ''}`}>{label}</span>
            </label>
        </div>
        { error != undefined && !error.isValid
        ? <div className="text-danger">{error.msg}</div>
        : ''}
  </div>
)
