import { Col, Modal } from "react-bootstrap";
import { BtnEmpty } from "../../../shared/BtnEmpty";

export function MultiImportModal({
    show,
    onHide,
    components = []
}) {

    const renderChildren = () => {
        return components.map((component, index) => {
            return <Col key={`multiimport-${index}`} className="d-flex   align-items-center">
                {component()}
            </Col>
        })
    }

    return (
        <Modal
            backdrop="static"
            show={show}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onHide}
            centered
        >
            <div className="bar"></div>
            <Modal.Header
                closeButton>
                <h2>IMPORTA RAPPORTI</h2>
            </Modal.Header>
            <Modal.Body className="row flex-column ms-4 align-content-center">
                {renderChildren()}
            </Modal.Body>
            <Modal.Footer>
                <Modal.Footer>
                    <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={onHide} />
                </Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}