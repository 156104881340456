import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from './Tabs';
import * as actions from "../../../../actions";
import RelationshipsList from "./RelationshipsList";
import elenco from "../../../../styles/images/elenco.png";
import spedire from "../../../../styles/images/spedire.png";
import storico from "../../../../styles/images/storico.png";
import photo from "../../../../styles/images/camera.png"
import calendariotab from "../../../../styles/images/calendarTab.png";
import arrow_l from "../../../../styles/images/arrow_l.png"
import MonthlyList from "../communications/MonthlyList";
import { Link } from "react-router-dom";
import ConsistencyHistoryList from "../receipts/ConsistencyHistoryList";
import * as Constants from "../../../../config/Constants";
import { CommunicationsTab } from '../communications/CommunicationsTab';
import YearlyBalanceList from '../balances/YearlyBalanceList';
import { CheckLicenseAndRedirect } from '../../../shared/auth/license/LicenseChecker';
import { formatDateForDisplay, isNotEmpty } from '../../../shared/Utility';
import { Col } from 'react-bootstrap';

//pulisce la riga di errore della fotografia
export function cleanErrorString(string) {
    if (string && string.includes("<<")) {
        let arr = string.split("<");
        arr.shift();
        return arr.join("<<");
    }
    return string;
}


class Relationships extends React.Component {
    constructor() {
        super();
        this.state = {
            subject: {},
            subjectId: '',
            errors: [],
            relationships: [],
            relationshipLocations: [],
            relationshipProvinces: [],
            currencies: [],
            role: [],
            communicationTypes: [],
            licenseExpired: true,
            filterParams: {},
            showTotalRelationshipResults: false

        }

        this.tabRef = React.createRef(null);
    }

    componentDidMount() {

        this.setState({ subjectId: this.props.match.params.id });
        this.getSubject(this.props.match.params.id);
        this.getRelationshipLocations();
        this.getAllCurrency();
        this.getAllRole();
        this.getCommunicationTypes();
    }

    componentDidUpdate(prev) {
        if (prev.auth?.user?.customer?.customerApplications !== this.props?.auth?.user?.customer?.customerApplications) {
            CheckLicenseAndRedirect(
                this.props.history,
                '/app/cora/dashboard',
                this.props?.auth?.user?.customer?.customerApplications,
                Constants.APPLICATION_CORA)

        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.relationshipLocations !== undefined && nextProps.relationshipLocations.length > 0) {
            let dataLocation = nextProps.relationshipLocations.map((value, index) => {
                return { name: value.location, key: index }
            });
            let dataProvince = nextProps.relationshipLocations.map((value, index) => {
                return { name: value.province, key: index }
            });
            this.setState({ relationshipLocations: dataLocation, relationshipProvinces: dataProvince })
        }
        if (nextProps.currencies !== undefined && nextProps.currencies.length > 0) {
            this.setState({ currencies: nextProps.currencies });
        }
        if (nextProps.roles !== undefined && nextProps.roles.length > 0) {
            this.setState({ roles: nextProps.roles });
        }
        if (nextProps.subject !== undefined) {
            this.setState({ subject: nextProps.subject });
        }
        if (nextProps.communicationTypes !== undefined && nextProps.communicationTypes.length > 0) {
            this.setState({ communicationTypes: nextProps.communicationTypes });
        }

    }

    //conservo i parametri per mantenere coerenti i filtri durante il cambio di tab
    setFilterParams = (params) => {
        this.setState({ filterParams: params });
    }

    getHistory = (subjectId) => {
        this.props.dispatch(actions.getCommunicationsHistoryBySubjectId(subjectId));
    };

    getRelationshipLocations() {
        this.props.dispatch(actions.getAllLocations());
    }
    getAllCurrency() {
        if (!this.props.currencies?.length) {
            this.props.dispatch(actions.getAllCurrency());
        }
    }
    getAllRole() {
        this.props.dispatch(actions.getAllRole());
    }
    getSubject(subjectId) {
        this.props.dispatch(actions.getCoraSubject(subjectId));
    }
    getCommunicationTypes() {
        this.props.dispatch(actions.getAllCommunicationTypes());
    }
    render() {
        const { relationships, subject } = this.props;
        return (
            <React.Fragment>
                <div className="row title-page justify-content-between">
                    <Col lg={6}>
                        <Link to="/app/cora/dashboard" >
                            <img src={arrow_l} className="px-4" alt="Torna a elenco soggetti" />
                            {this.state.subject?.personType === Constants.PNF ? this.state.subject?.companyName : this.state.subject?.lastName + " " + this.state.subject?.firstName}
                        </Link>
                    </Col>
                    <Col lg={6}>
                        {isNotEmpty(this.props.delayedCommunicationDate)  &&
                            <div className='me-auto' style={{ fontSize: "1.5rem" }}>
                                    <strong className='pulse text-light px-2'>INVIO DIFFERITO IMPOSTATO AL {formatDateForDisplay(this.props.delayedCommunicationDate)}</strong>

                            </div>}
                    </Col>
                </div>
                <div className="table-custom">
                    <Tabs ref={this.tabRef}>
                        <div label="ELENCO RAPPORTI" icon={elenco} id={"relationships"}>
                            <RelationshipsList relationships={relationships}
                                relationshipLocations={this.state.relationshipLocations}
                                relationshipProvinces={this.state.relationshipProvinces}
                                currencies={this.state.currencies}
                                subject={subject}
                                subjectId={this.state.subjectId}
                                roles={this.state.roles}
                                communicationTypes={this.state.communicationTypes}
                                filterParams={this.state.filterParams}
                                setFilterParams={(params) => this.setFilterParams(params)}
                                showTotalResults={this.state.showTotalRelationshipResults}
                                setShowTotalResults={(showTotalResults)=>this.setState({showTotalRelationshipResults:showTotalResults})}
                                onImportedBalances={()=>this.tabRef.current.setState({ activeTab: "SALDI ANNUALI" })}
                                {...this.props}
                            />
                        </div>
                        <div label="RAPPORTI DA SPEDIRE" icon={spedire} id={"toSend"}>
                            <MonthlyList subjectId={this.state.subjectId} subject={subject} {...this.props} />
                        </div>
                        <div label="SALDI ANNUALI" icon={calendariotab} id={"balance"}>
                            <YearlyBalanceList subjectId={this.state.subjectId} {...this.props} />
                        </div>
                        <div label="STORICO COMUNICAZIONI" icon={storico} id={"history"}>
                            <CommunicationsTab  subjectId={this.state.subjectId} {...this.props} />
                        </div>
                        <div label="FOTOGRAFIA/ESITI" icon={photo} id={"photo"}>
                            <ConsistencyHistoryList subjectId={this.state.subjectId} {...this.props} />
                        </div>

                    </Tabs>
                </div>



            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {

    return {
        auth: state.auth,
        relationships: state.relationships.data,
        subject: state.subject.data,
        relationshipLocations: state.locations.customLocations,
        currencies: state.currencies.data,
        roles: state.roles.data,
        communicationTypes: state.communicationTypes.data.filter(el => Constants.exludedCommTypes.indexOf(el.code) === -1),
        delayedCommunicationDate: state.delayedCommunicationDate
    }
}

export default connect(mapStateToProps)(Relationships);
