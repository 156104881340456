import { isEmpty, isNotEmpty } from "./Utility";

import * as actions from "../../actions";
import { PopupError } from "./PopupError";
import { PopupConfirm, titleColors } from "./PopupConfirm";
import { text } from "@fortawesome/fontawesome-svg-core";
import { APPLICATION_GENERIC_ERROR } from "../../config/Constants";

/**
 * Questa funzione ritorna un booleano per riuscire ha capire se i file importati sono corretti
 * @param {*} files lista di file presente nello state, quelli caricati dall'utente 
 * @returns ritorna false se i file non sono corretti, o manca qualcosa, true invece se è tutto corretto
 */
export function checkIfAllFileHaveBeenGivenByTheUser(files) {
  if (files.length > 3 || files === 2) return false;

  let hasUtecP12 = false;
  let hasUtefP12 = false;
  /**
   * Il file "UTENTE.KS" non è piu obbligatorio (Giugno 2022)
   */

  for (let i = 0; i < files.length; i++) {
    const fileName = files[i].name?.toUpperCase();
    if (!fileName?.includes(".P12") && !fileName?.includes(".KS")) return false;

    if (fileName?.includes("UTEC.P12")) hasUtecP12 = true;
    if (fileName?.includes("UTEF.P12")) hasUtefP12 = true;
    //if (files[i].name?.toUpperCase().includes("UTENTE.KS")) utenteKS = true;
  }
  if (hasUtecP12 && hasUtefP12) {
    return true;
  }
  return false;
}

/**
 * Questa funzione gestisce il caricamento dei certificati mettendoli sullo state, e controlla se sono corretti
 * @param {*} val 
 * @param {*} setStateFunction funzione che cambia lo state
 * @param {*} state tutti gli stati della funzione padre 
 * @param {*} coraSubjectSid  il coraSubjectSid passato dalle properties
 * @param {*} onSave  funzione da eseguire dopo il salvataggio
 * 
 */
export function handleChangeCerts(
  files,
  setStateFunction = ()=>undefined,
  state,
  coraSubjectSid,
  onSave = ()=>undefined
) {
  //let files = val;
  let checkAllFile = checkIfAllFileHaveBeenGivenByTheUser(files);
  if (isEmpty(files) === true || checkAllFile === false) {
    setStateFunction({
      errorCertificates: {
        isValid: false,
        msg: "Selezionare contemporaneamente i certificati UTEC e UTEF (e UTENTE.KS)",
      },
    });
  } else {
    let newState = Object.assign({}, state);
    newState.certificateFiles = files;
    let coraSubjectSidId =
      newState.coraSubjectSid.sidNumber === coraSubjectSid?.sidNumber
        ? coraSubjectSid?.id
        : "";
    if (coraSubjectSidId === "") coraSubjectSidId = null;
    newState.coraSubjectSid = {
      ...newState.coraSubjectSid,
      id: coraSubjectSidId,
      certificate1: "",
      certificate2: "",
      certificate3: "",
      password: newState.coraSubjectSid.password,
      email: newState.coraSubjectSid.email || state.defaultEmail,
      sidNumber: newState.coraSubjectSid.sidNumber,
      expirationDate: newState.coraSubjectSid.expirationDate,
    };
    for (let i = 0; i < files.length; i++) {
      if (files[i].name.toUpperCase().includes("UTEC.P12"))
        newState.coraSubjectSid.certificate1 = files[i].name;
      if (files[i].name.toUpperCase().includes("UTEF.P12"))
        newState.coraSubjectSid.certificate2 = files[i].name;
      if (files[i].name.toUpperCase().includes("UTENTE.KS"))
        newState.coraSubjectSid.certificate3 = files[i].name;
    }
    newState.errorCertificates = { isValid: true, msg: "" };
    setStateFunction(newState);
    if(isNotEmpty(coraSubjectSidId) ){
      saveSid(files
        , newState.coraSubjectSid
        , newState.coraSubjectSid.fiscalCode
        , newState.coraSubjectSid.application
        , ()=> onSave()
        , ()=> PopupError({text: "Si è verificato un errore durante il salvataggio dei certificati"}))
    } else {
      onSave()
    }
  }
}

/**
 * Salva il SID, è possibile passare dei callback in caso di successo, errore, e post chiamata
 * @param {Array} files opzionale
 * @param {Object} coraSubjectSid 
 * @param {string} fiscalCode 
 * @param {string} application 
 * @param {function} successCallback opzionale
 * @param {function} errCallback opzionale
 * @param {function} thenCallback opzionale
 */
export function saveSid(files = []
  , coraSubjectSid
  , fiscalCode
  , application
  , successCallback = (res) => undefined
  , errCallback = (err) => undefined
  , thenCallback = () => undefined) {

  if(isEmpty(coraSubjectSid.sidNumber)) {
    Promise.resolve()
    .then(()=>successCallback(undefined))
    .then(()=>thenCallback());
    return;
  }
  let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
  coraSubjectSid.application = application;
  coraSubjectSid.fiscalCode = fiscalCode;
  formData.append("coraSubjectSid", new Blob([JSON.stringify(coraSubjectSid)], { type: "application/json" }));


  if (isEmpty(coraSubjectSid.id)) {
    //verifico numero SID
    actions.verifySidNumber(coraSubjectSid.sidNumber)
      .then((res) => {
        if(!isEmpty(res)){
          PopupConfirm({
            titleColor: titleColors.ALERT,
            text: res,
            handleClickConfirm : ()=>create(formData, successCallback, errCallback, thenCallback)
          })
        } else {
          create(formData, successCallback, errCallback, thenCallback);

        }
      },
    (err)=> {
      if(err.errorKey === 'sidnumber_invalid'){
        PopupError({text: err.title})
      } else {
        PopupError({text: APPLICATION_GENERIC_ERROR})
      }
    })


  } else {
    actions.updateCoraSubjectSid(formData).then(
      (res) => {
        successCallback(res);
      },
      (err) => {
        errCallback(err);
      }).then(() => thenCallback())
  }

}

function create(formData, successCallback, errCallback, thenCallback) {
  actions.createCoraSubjectSid(formData).then(
    (res) => {

      successCallback(res);
    },
    (err) => {
      errCallback(err);
    }).then(() => thenCallback());
}

/**
 * valida numero ed emailSid, setta lo stato del componente chiamante
 * @param {string} sidNumber 
 * @param {string} sidEmail 
 * @param {function} setStateFunction 
 * @param {Object} errorSidNumber 
 * @param {Object} errorSidEmail 
 * @param {string} errorSidNumberProp 
 * @param {string} errorEmailProp 
 * @returns 
 */
export function validateSid(sidNumber
  , sidEmail
  , setStateFunction
  , errorSidNumber
  , errorSidEmail
  , errorSidNumberProp
  , errorEmailProp) {
  let formIsValid = true;
  let stateObj = {};
  stateObj[errorSidNumberProp] = errorSidNumber;
  stateObj[errorEmailProp] = errorSidEmail;

  if (isNotEmpty(sidNumber) && sidNumber.indexOf("S") !== 0) {
    formIsValid = false;
    stateObj[errorSidNumberProp] = { isValid: false, msg: "Campo deve iniziare con una 'S'" };
  } else if (isNotEmpty(sidNumber) && sidNumber.length !== 8) {
    formIsValid = false;
    stateObj[errorSidNumberProp] = { isValid: false, msg: "Campo deve essere lungo 8 caratteri" };
  }
  if (isNotEmpty(sidEmail)) {
    let lastAtPos = sidEmail.lastIndexOf('@');
    let lastDotPos = sidEmail.lastIndexOf('.');

    if (!(lastAtPos < lastDotPos && lastAtPos > 0 && sidEmail.indexOf('@@') === -1 && lastDotPos > 2
      && (sidEmail.length - lastDotPos) > 2)) {
      formIsValid = false;
      stateObj[errorEmailProp] = { isValid: false, msg: "Formato email non valido" };
    } else if (sidEmail.toUpperCase().indexOf("AGENZIAENTRATE") !== -1) {
      stateObj[errorEmailProp] = { isValid: false, msg: "Inserire un destinatario diverso dall'agenzia delle entrate" };
      formIsValid = false;
    }
  }
  setStateFunction(stateObj);
  return formIsValid;
}