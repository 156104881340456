import React from 'react';
import { RelationshipRow } from "./RelationshipRow";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import CreateRelationshipForm from "./relationship-create/CreateRelationshipForm";
import { PopupDelete } from "../../../shared/PopupDelete";
import * as actions from "../../../../actions";
import { PopupError } from "../../../shared/PopupError";
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactComponent as ICalendar } from '../../../../styles/images/svg/calendar-alt-regular.svg';
import { ReactComponent as IList } from '../../../../styles/images/svg/list-ul-solid.svg';
import { ReactComponent as ISearch } from "../../../../styles/images/svg/search-solid.svg";
import "../../../../styles/relationship-form.css"
import { ReactComponent as IPdfFile } from "../../../../styles/images/svg/file-pdf.svg";
import { ReactComponent as IImport } from "../../../../styles/images/svgs/regular/file-import.svg";

import { ReactComponent as IExclude } from "../../../../styles/images/svg/do-not-enter.svg";
import { ReactComponent as IDelete } from "../../../../styles/images/svg/trash-solid.svg";
import { BwmSelect } from "../../../shared/form/BwmSelect";
import { BwmInput } from "../../../shared/form/BwmInput";
import moment from "moment";
import * as Constants from "../../../../config/Constants";
import { ReactComponent as ISort } from "../../../../styles/images/svgs/regular/sort.svg";

import { Col, DropdownButton, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay, isEmpty, isNotEmpty } from '../../../shared/Utility';
import RowSpinner from '../../../shared/spinner/Spinner';
import ImportConsitencyRelationshipsForm from '../import/ImportConsistencyRelationshipsForm';
import { MultiImportModal } from '../import/MultiImportModal';
import { ImportDataModal } from '../import/ImportDataModal';
import CoraImportRelationsModal from '../subject-cora/CoraImportRelationsModal';
import { StarTooltip } from '../../../shared/tooltips/Tooltip';
import { PopupConfirm, titleColors } from '../../../shared/PopupConfirm';
import { YearlyBalanceImportModal } from '../balances/YearlyBalanceImportModal';

registerLocale("it", it);


const DatePickerCustomInput = React.forwardRef(({ value, onClick, isDisabled }) => (
    <button type={"button"} className={`btn btn-outline-primary btn-empty px-4 btn-sm ms-1 me-1 ${isDisabled && 'disabled'}`} disabled={isDisabled} onClick={onClick}>
        <ICalendar className={"padding-bottom-4px"} fill={` ${isDisabled ? '#FFFFFF' : '#128186'}`} width="15" />&nbsp;
        Imposta Data Chiusura
    </button>
));

const CommunicationToggle = React.forwardRef(({ children: isDisabled, onClick }, ref) => (//children = componenti sottostanti
    <button type={"button"} className={`btn btn-outline-primary btn-empty px-4 btn-sm ms-1 me-1 ${isDisabled && 'disabled'}`} disabled={isDisabled} ref={ref} onClick={(e) => {
        if (!isDisabled) {
            onClick(e);
        }
    }}
    >
        <IList className={"padding-bottom-4px"} fill={` ${isDisabled ? '#FFFFFF' : '#128186'}`} width="15" />
        &nbsp; Da comunicare come &nbsp;
        &#9660;
    </button>
));

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 500;
const filterTypes = {
    DEFAULT: () => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "DEFAULT",
            searchTerm: ""
        }
    }, OPEN_ONLY: () => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "OPEN_ONLY",
            searchTerm: ""
        }
    }, CLOSED_ONLY: () => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "CLOSED_ONLY",
            searchTerm: ""
        }
    }, CANCELED_AND_COMMUNICATED: () => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "CANCELED_AND_COMMUNICATED",
            searchTerm: ""
        }
    }, INCLUDED_IN_NEXT_COMMUNICATION: () => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "INCLUDED_IN_NEXT_COMMUNICATION",
            searchTerm: ""
        }
    },
    BY_NAME: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_NAME",
            searchTerm: query
        }
    }, BY_START_DATE: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_START_DATE",
            searchTerm: query
        }
    }, BY_END_DATE: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_END_DATE",
            searchTerm: query
        }
    }, BY_IDENTIFIER: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_IDENTIFIER",
            searchTerm: query
        }
    }, BY_COMMENT: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_COMMENT",
            searchTerm: query
        }
    }, BY_ID_RESULT: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_ID_RESULT",
            searchTerm: query
        }
    }, BY_FISCAL_CODE: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_FISCAL_CODE",
            searchTerm: query
        }
    }, BY_MISSING_IN_CONSISTENCY: (query) => {
        return {
            pageNumber: DEFAULT_PAGE,
            pageSize: DEFAULT_PAGE_SIZE,
            searchField: "BY_MISSING_IN_CONSISTENCY",
            searchTerm: query
        }
    }
}
const searchNames = {
    BY_NAME: "BY_NAME",
    BY_IDENTIFIER: "BY_IDENTIFIER",
    BY_START_DATE: "BY_START_DATE",
    BY_END_DATE: "BY_END_DATE",
    BY_COMMENT: "BY_COMMENT",
    BY_ID_RESULT: "BY_ID_RESULT",
    BY_FISCAL_CODE: "BY_FISCAL_CODE",
    BY_MISSING_IN_CONSISTENCY: "BY_MISSING_IN_CONSISTENCY"
}


const defaultFilterParams = {
    filter: filterTypes.DEFAULT,
    searchTerm: '',
    searchType: searchNames.BY_NAME,
    searchPlaceholder: "es. Rossi Mario, Star Infostudio srl"
};
class RelationshipsList extends React.Component {

    constructor(props) {
        super();
        this.state = ({
            loading: true,
            allCheck: false,
            checkedItems: new Map(),
            isDisabled: true,
            endDateUpload: new Date(),
            searchIsOpen: false,
            showImportModal: false,
            showImportConsistencyModal: false,
            showSubjectImportModal: false,
            showRelationshipImportModal: false,
            showIdentifiers: false,
            relationships: [],//Rapporti filtrati, i rapporti effettivi vengono dalle props
            relationshipIdsToStamp: [],
            sortCompleteName: 1,
            sortFiscalCode: 1,
            sortStartDate: 1,
            sortEndDate: 1,
            sortRelType: 1,
            sortComType: 1,
            sortComment: 1,
            sortIdentifier: 1,
            searchOptions: [
                {
                    id: searchNames.BY_NAME,
                    description: "Denominazione"
                },
                {
                    id: searchNames.BY_FISCAL_CODE,
                    description: "Codice fiscale"
                },
                {
                    id: searchNames.BY_IDENTIFIER,
                    description: "Codice Univoco"
                },
                {
                    id: searchNames.BY_START_DATE,
                    description: "Data inizio Rapporto"
                },
                {
                    id: searchNames.BY_END_DATE,
                    description: "Data fine Rapporto"
                },
                {
                    id: searchNames.BY_COMMENT,
                    description: "Note"
                },
                {
                    id: searchNames.BY_ID_RESULT,
                    description: "ID Esito"
                },
                {
                    id: searchNames.BY_MISSING_IN_CONSISTENCY,
                    description: "Assenti in fotografia"
                }

            ],
        })
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handlerDeleteRelationship = this.handlerDeleteRelationship.bind(this);
        this.openPopupDelete = this.openPopupDelete.bind(this);
        this.reloadRelationships = this.reloadRelationships.bind(this);
    }


    componentDidMount() {

        if (Object.keys(this.props.filterParams).length === 0) {
            this.props.setFilterParams(defaultFilterParams)
        } else {
            //ricaricare rapporti con parametri precedentemente inseriti
            this.reloadRelationships();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.subjectId != this.props.subjectId) {
            this.searchRelationships(defaultFilterParams.filter());
            /*             this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId, defaultFilterParams.filter())); */
        }
        if (prevProps.relationships != this.props.relationships) {
            this.setState({ relationships: this.props.relationships, loading: false })
        }
        const totalElements = this.props.totalElements;
        const filterParams = this.props.filterParams;
        if (prevProps.totalElements != totalElements
            && totalElements > DEFAULT_PAGE_SIZE
            && filterParams.filter.name != filterTypes.DEFAULT.name
            && !this.props.showTotalResults) {
            PopupConfirm({
                titleColor: titleColors.ALERT,
                title: "Selezione rapporti",
                text: `La selezione riguarda ${totalElements} rapporti. La visualizzazione potrebbe essere lenta: proseguire?`,
                cancel: `Visualizza i primi ${DEFAULT_PAGE_SIZE} rapporti`,
                confirm: `Visualizza tutti i rapporti`,
                handleClickConfirm: () => {

                    this.props.setFilterParams(filterParams)
                    this.props.setShowTotalResults(true)
                    this.setState({ loading: true })
                    this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId
                        , { ...filterParams, searchTerm: filterParams.searchTerm, pageSize: totalElements, pageNumber: 0 }));

                }
            })
            this.setState({ loading: false })
        }

    }


    dynamicSearch = (e) => {
        this.props.setFilterParams({ ...this.props.filterParams, searchTerm: e.target.value })
    }

    handlerChangeSearch = (e) => {
        let option = e.target.value;
        if (option) {
            let placeholder = '';
            switch (option) {
                case searchNames.BY_NAME:
                    // Search by completeName
                    placeholder = "es. Rossi Mario, Star Infostudio srl";
                    break;
                case searchNames.BY_IDENTIFIER:
                    // Search by identifier
                    placeholder = "es. PTR65B14I288W_202011_23112020...";
                    break;
                case searchNames.BY_START_DATE:
                case searchNames.BY_END_DATE:
                    // Search by startDate-endDate
                    placeholder = "es. 15/12/2020, 08/2020";
                    break;
                case searchNames.BY_COMMENT:
                    // Search by comment
                    placeholder = "es. NOTE";
                    break;
                case searchNames.BY_ID_RESULT:
                    // Search by id esito
                    placeholder = "es. H000002107466";
                    break;
                case searchNames.BY_FISCAL_CODE:
                    // Cerca per codice fiscale
                    placeholder = "es. ABCDEF123";
                    break;
            }
            this.props.setFilterParams({ ...this.props.filterParams, searchType: option, searchPlaceholder: placeholder })

        }
    }



    searchRelationships(searchObject) {
        this.setState({ loading: true });
        let pageSizeSearchObject = { ...searchObject };
        if (searchObject?.searchField === "DEFAULT") {
            this.props.setShowTotalResults(false);
        } else if (this.props.showTotalResults) {
            pageSizeSearchObject.pageSize = this.props.totalElements
        }
        this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId, pageSizeSearchObject))
    }



    reloadRelationships() {
        this.searchRelationships(this.props.filterParams.filter(this.props.filterParams.searchTerm));
        /* this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId,
            this.props.filterParams.filter(this.props.filterParams.searchTerm))); */
    }

    closeSearchBox() {
        this.setState({ searchIsOpen: false });
    }

    abortSearch() {
        this.setState({ searchIsOpen: false, loading: true });
        this.props.setFilterParams(defaultFilterParams)
        this.searchRelationships(defaultFilterParams.filter())
        /* this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId, defaultFilterParams.filter())); */
    }

    openSearchBox() {

        document.getElementById("relationships-search-bar").style.position = "absolute";
        document.getElementById("relationships-search-bar").style.top = "31%";
        document.getElementById("relationships-search-bar").style.left = "25%";
        this.setState({ searchIsOpen: !this.state.searchIsOpen });


    }

    confirmSearch() {
        if (isNotEmpty(this.props.filterParams.searchTerm)) {
            this.setState({ searchIsOpen: false, loading: true });
            const filter = filterTypes[this.props.filterParams.searchType];
            this.props.setFilterParams({
                ...this.props.filterParams
                , filter: filter
            })
            this.searchRelationships(filter(this.props.filterParams.searchTerm))
            /*  this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId, filter(this.props.filterParams.searchTerm))); */
        }

    }

    handleCheckChange(event) {
        if (event !== undefined) {
            let checkedItems = new Map(this.state.checkedItems);
            checkedItems.set(parseInt(event.target.value), event.target.checked);
            this.setState({ checkedItems: checkedItems });
            if (!event.target.checked) {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.remove("bg-cobalt", "bold");
            } else {
                let element = document.getElementById(event.target.id);
                if (element)
                    element.classList.add("bg-cobalt", "bold");
            }
            let count = 0;
            for (const checkedItem of checkedItems) {
                if (checkedItem[1]) {
                    this.setState({ isDisabled: false });
                    return;
                }
                if (!checkedItem[1]) {
                    count++;
                }
            }
            if (count === checkedItems.size) {
                this.setState({ isDisabled: true });
            }
        }
    }

    handleFilterChange(filter) {
        if (filter) {
            this.setState({ searchIsOpen: false, loading: true });
            this.searchRelationships(filter());
            /*  this.props.dispatch(actions.getRelationshipsBySubjectId(this.props.subjectId, filter())); */
            const filterParams = { ...this.props.filterParams, searchField: filter.name, filter: filter };
            this.props.setFilterParams(filterParams);

        }
    }

    mapCheckedRelationShips = (items, relationships) => {
        relationships.map((relationship, index) => (
            items.set(relationship.id, true)
        ));
        return items;
    }

    handleAllCheckChange = event => {
        this.setState({ allCheck: event.target.checked });
        if (event.target.checked) {

            let items = new Map();
            if (!this.state.relationships || this.state.relationships.length < 1) {
                items = this.mapCheckedRelationShips(items, this.props.relationships);
            } else {
                items = this.mapCheckedRelationShips(items, this.state.relationships);

            }


            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.add("bg-cobalt", "bold");
                }
            }

            this.setState({ checkedItems: items, isDisabled: false })

        } else {
            let rowIds = document.querySelectorAll('[id^="row-"]');
            for (let i = 0; i < rowIds.length; i++) {
                if (rowIds[i].localName === "div") {
                    rowIds[i].classList.remove("bg-cobalt", "bold");
                }
            }
            this.setState({ checkedItems: new Map(), isDisabled: true });
        }
    };

    openPopupDelete = () => {
        PopupDelete({
            operazione: "Rapporto",
            handleClickConfirm: this.handlerDeleteRelationship
        });
    };

    handlerDeleteRelationship = () => {
        let relationshipsToUpdate = [];
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                let relationshipChecked = this.props.relationships.find(rel => rel.id === parseInt(checkedItem[0]))
                relationshipsToUpdate.push(relationshipChecked);
            }
        }
        this.setState({ loading: true });
        actions.deleteCoraRelationship(relationshipsToUpdate).then(
            () => {

            },
            (errors) => PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR })
        )
            .then(() => {
                this.reloadRelationships();
            })
            .then(() => this.setState({ loading: false }))
        this.resetCheckBox();
    };

    onChangeEndDate = (date) => {
        this.setState({ endDateUpload: date, calendarOpen: true });
    }

    handlerUpdateEndDate = () => {

        let ids = []
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                let relationshipChecked = this.props.relationships.find(rel => rel.id === parseInt(checkedItem[0]))
                ids.push(relationshipChecked.id);
            }
        }

        if (ids.length > 0) {
            this.setState({ loading: true });
            actions.updateCoraRelationshipEndDate({ ids: ids, endDate: this.state.endDateUpload }, this.props.subject?.id).then(
                () => {

                },
                (errors) => PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
            )
                .then(() => {
                    this.reloadRelationships();
                })
                .then(() => this.setState({ loading: false }))
        }


        this.resetCheckBox();
    };

    handlerDontSendCommunication = () => {
        let checkedIds = [];
        for (const checkedItem of this.state.checkedItems) {
            if (checkedItem[1]) {
                checkedIds.push(parseInt(checkedItem[0]));
            }
        }
        this.setState({ loading: true });
        actions.removeNextCommunication(checkedIds).then(
            () => {

            },
            (errors) => PopupError({ ...this.props, text: Constants.APPLICATION_GENERIC_ERROR })
        )
            .then(() => {
                this.reloadRelationships();
            })
            .then(() => this.setState({ loading: false }))
        this.resetCheckBox();
    };

    handlerUpdateCommType = (communicationTypeId) => {
        if (this.props.subject?.coraSubjectSid) {
            let ids = [];
            for (const checkedItem of this.state.checkedItems) {
                if (checkedItem[1]) {
                    let relationshipChecked = this.props.relationships.find(rel => rel.id === parseInt(checkedItem[0]));
                    ids.push(relationshipChecked.id);


                }
            }

            this.setState({ loading: true });
            actions.updateCoraRelationshipCommunicationType({ ids: ids, communicationTypeId: communicationTypeId }, this.props.subject.id).then(
                () => {

                },
                (errors) => PopupError({ text: Constants.APPLICATION_GENERIC_ERROR })
            )
                .then(() => {
                    this.reloadRelationships();
                })
                .then(() => this.setState({ loading: false }))
            this.resetCheckBox();
        } else {
            PopupError({ text: "E' necessario inserire le credenziali SID del soggetto per poter continuare" })
        }

    };

    resetCheckBox = () => {
        let rowIds = document.querySelectorAll('[id^="row-"]');
        for (let i = 0; i < rowIds.length; i++) {
            if (rowIds[i].localName === "div") {
                rowIds[i].classList.remove("bg-cobalt", "bold");
            }
        }
        this.setState({ checkedItems: new Map(), isDisabled: true, allCheck: false });

        document.getElementsByName('relationshipCheck').forEach(el => {
            if (el.checked) {
                el.checked = false
            }
        })
    }

    setDropdownCommType = () => {
        return this.props.communicationTypes.map((option, index) =>
            <Dropdown.Item
                key={index}
                eventKey={option.id}
            >
                {option.code + '-' + option.description}
            </Dropdown.Item>
        )
    };


    callDownloadPdfListService = () => {
        this.setState({ loading: true });
        let companyName = this.props.subject?.personType === Constants.PNF ? this.props.subject?.companyName : this.props.subject?.lastName + " " + this.props.subject?.firstName;

        let relationshipIds = [];
        for (const [key, value] of this.state.checkedItems) {
            if (value) {
                relationshipIds.push(key)
            }
        }
        actions.downloadRelationshipPdf(relationshipIds
            , this.props.subjectId
            , this.props.filterParams.filter(this.props.filterParams.searchTerm)
        ).then(
            (response) => {
                if (response.length > 0) {
                    downloadFile(base64ToArrayBuffer(response), companyName + "_RELATIONSHIPS.pdf")
                }
            },
            (error) => {
                PopupError({ text: "Si è verificato un errore nello scaricamento del file" })
            }
        )
            .then(() => this.setState({ loading: false }));


    };

    closeAllRowDetails() {
        let details = document.getElementsByClassName("bg-blue bold");
        if (details) {
            for (let i = 0; i < details.length; i++) {
                let detail = "detail-" + details[i].id.replace('row-', '');
                document.getElementById(detail).click();
            }
        }
    }

    sortBy = (val) => {
        if (val) {
            let relationships = (this.state.relationships && this.state.relationships.length > 0) ? this.state.relationships : this.props.relationships;
            let relationshipsOrdered = relationships;
            switch (val) {
                case "completeName":
                    // Sort by completeName
                    relationshipsOrdered = relationships.sort((a, b) => (a.completeName > b.completeName) ? this.state.sortCompleteName : ((b.completeName > a.completeName) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortCompleteName: -this.state.sortCompleteName });
                    break;
                case "fiscalCode":
                    // Sort by relationship type
                    relationshipsOrdered = relationships.sort((a, b) => (a.fiscalCode > b.fiscalCode) ? this.state.sortFiscalCode : ((b.fiscalCode > a.fiscalCode) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortFiscalCode: -this.state.sortFiscalCode });
                    break;
                case "relType":
                    // Sort by relationship type
                    relationshipsOrdered = relationships.sort((a, b) => (a.relationshipType?.code > b.relationshipType?.code) ? this.state.sortRelType : ((b.relationshipType?.code > a.relationshipType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortRelType: -this.state.sortRelType });
                    break;
                case "comType":
                    // Sort by communication type
                    relationshipsOrdered = relationships.sort((a, b) => (a.coraCommunicationType?.code > b.coraCommunicationType?.code) ? this.state.sortComType : ((b.coraCommunicationType?.code > a.coraCommunicationType?.code) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortComType: -this.state.sortComType });
                    break;
                case "startDate":
                    // Sort by startDate
                    relationshipsOrdered = relationships.sort((a, b) => (moment(a.startDate) > moment(b.startDate)) ? this.state.sortStartDate : ((moment(b.startDate) > moment(a.startDate)) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortStartDate: -this.state.sortStartDate });
                    break;
                case "endDate":
                    // Sort by endDate
                    relationshipsOrdered = relationships.sort((a, b) => (moment(a.endDate || new Date()) > moment(b.endDate || new Date())) ? this.state.sortEndDate : ((moment(b.endDate || new Date()) > moment(a.endDate || new Date())) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortEndDate: -this.state.sortEndDate });
                    break;
                case "comment":
                    relationshipsOrdered = relationships.sort((a, b) => (a.comment > b.comment) ? this.state.sortComment : ((b.comment > a.comment) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortComment: -this.state.sortComment });
                    break;
                case "identifier":
                    relationshipsOrdered = relationships.sort((a, b) => (a.identifier > b.identifier) ? this.state.sortIdentifier : ((b.identifier > a.identifier) ? -1 : 0));
                    if (navigator.userAgent.indexOf("Firefox") > 0) {
                        relationshipsOrdered.reverse();
                    }
                    this.setState({ relationships: relationshipsOrdered, sortIdentifier: -this.state.sortIdentifier });
                    break;
                default:
                    return;
            }
        }
    }





    renderHeaderButtons(subject, subjectId, relationshipTypes, relationshipLocations, relationshipProvinces, currencies, roles, communicationTypes) {
        const relationshipListSize = Object.keys(this.props.relationships).length;
        const searchActive = !this.state.searchIsOpen && this.props.filterParams.searchTerm && searchNames[this.props.filterParams.filter.name];
        const selectedFilter = this.props.filterParams.filter;
        return (
            <React.Fragment>
                <Col lg={1}>
                    <Dropdown onSelect={(eventKey) => {
                        this.handleFilterChange(filterTypes[eventKey])
                    }}>

                        <DropdownButton title={"Mostra"} bsPrefix={"dropdown-menu-green btn-fill"}>
                            <Dropdown.Item eventKey={"DEFAULT"} active={selectedFilter === filterTypes.DEFAULT}
                                onSelect={() => this.handleFilterChange(filterTypes.DEFAULT)}>
                                {selectedFilter === filterTypes.DEFAULT && <strong>{">"}</strong>}
                                {"Elenco iniziale"}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"OPEN_ONLY"} active={selectedFilter === filterTypes.OPEN_ONLY}
                                onSelect={() => this.handleFilterChange(filterTypes.OPEN_ONLY)}>
                                {selectedFilter === filterTypes.OPEN_ONLY && <strong>{">"}</strong>}
                                {"Rapporti solo aperti"}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"CLOSED_ONLY"} active={selectedFilter === filterTypes.CLOSED_ONLY}
                                onSelect={() => this.handleFilterChange(filterTypes.CLOSED_ONLY)}>
                                {selectedFilter === filterTypes.CLOSED_ONLY && <strong>{">"}</strong>}
                                {"Rapporti chiusi"}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"CANCELED_AND_COMMUNICATED"} active={selectedFilter === filterTypes.CANCELED_AND_COMMUNICATED}
                                onSelect={() => this.handleFilterChange(filterTypes.CANCELED_AND_COMMUNICATED)}>
                                {selectedFilter === filterTypes.CANCELED_AND_COMMUNICATED && <strong>{">"}</strong>}
                                {"Rapporti cancellati"}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"INCLUDED_IN_NEXT_COMMUNICATION"} active={selectedFilter === filterTypes.INCLUDED_IN_NEXT_COMMUNICATION}
                                onSelect={() => this.handleFilterChange(filterTypes.INCLUDED_IN_NEXT_COMMUNICATION)}>
                                {selectedFilter === filterTypes.INCLUDED_IN_NEXT_COMMUNICATION && <strong>{">"}</strong>}
                                {"Rapporti da comunicare"}
                            </Dropdown.Item>
                            <Dropdown.Item eventKey={"BY_MISSING_IN_CONSISTENCY"} active={selectedFilter === filterTypes.BY_MISSING_IN_CONSISTENCY}
                                onSelect={() => this.handleFilterChange(filterTypes.BY_MISSING_IN_CONSISTENCY)}>
                                {selectedFilter === filterTypes.BY_MISSING_IN_CONSISTENCY && <strong>{">"}</strong>}
                                {"Assenti in fotografia"}
                            </Dropdown.Item>
                        </DropdownButton>
                    </Dropdown>
                </Col>
                <Col lg={9}>
                    <div className="btn-group" role="group" aria-label="action buttons">
                        <div className='ms-1 me-1'>
                            <button type="button" className={`btn  btn-empty px-4 btn-sm w-100}`}
                                onClick={(e) => this.setState({ showImportModal: true })}>
                                <IImport className={"padding-bottom-4px"} fill={`#128186`} width="15" />
                                &nbsp; Importa
                            </button>
                        </div>
                        <div className='ms-1 me-1'>
                            <button type="button" className={`btn  btn-empty px-4 btn-sm w-100 ${this.state.isDisabled && 'disabled'}`} disabled={this.state.isDisabled} onClick={(e) => this.handlerDontSendCommunication()}>
                                <IExclude className={"padding-bottom-4px"} fill={` ${this.state.isDisabled ? '#FFFFFF' : '#128186'}`} width="15" />
                                &nbsp; Escludi dalla comunicazione
                            </button>
                        </div>
                        <div className='ms-1 me-1'>
                            <button type="button" className={`btn btn-empty btn-sm px-4 w-100 ${(relationshipListSize === 0) && 'disabled'}`}
                                disabled={(relationshipListSize === 0)}
                                onClick={() => this.callDownloadPdfListService()}>
                                <IPdfFile className={"padding-bottom-4px"} width="12" fill={` ${(relationshipListSize === 0) ? '#FFFFFF' : '#128186'}`} />
                                &nbsp; Stampa Rapporti
                            </button>
                        </div>
                        <div className='ms-1 me-1'>
                            <button type="button" className={`btn btn-empty px-4  btn-sm w-100 ${this.state.isDisabled && 'disabled'}`}
                                onClick={() => this.openPopupDelete()} disabled={this.state.isDisabled}>
                                <IDelete className={"padding-bottom-4px"} width="12" fill={` ${(this.state.checkedItems.size === 0) ? '#FFFFFF' : '#128186'}`} />
                                &nbsp; Elimina
                            </button>
                        </div>
                        <div>
                            <DatePicker
                                onChange={date => this.setState({ endDateUpload: date })}
                                selected={this.state.endDateUpload}
                                customInput={<DatePickerCustomInput isDisabled={this.state.isDisabled} />}
                                locale="it"
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                disabled={this.state.isDisabled}
                                shouldCloseOnSelect={false}
                            >
                                <div className='text-center'>
                                    <button type="button"
                                        className={`btn btn-outline-primary  btn-empty m-2 px-4 btn-sm `}
                                        onClick={() => this.handlerUpdateEndDate()} >{"Conferma: " + formatDateForDisplay(this.state.endDateUpload)}</button>
                                </div>
                            </DatePicker>
                        </div>
                        <Dropdown onSelect={(option) => this.handlerUpdateCommType(option)}>
                            <Dropdown.Toggle as={CommunicationToggle} variant="success" id="dropdown-comm-type">
                                {this.state.isDisabled}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    this.setDropdownCommType()
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className={"parent"} id={"searchButton"}>
                            <button type="button" className={`btn btn-outline-primary btn-empty px-4 btn-sm margin-left-5px`}
                                onClick={() => {
                                    if (searchActive) {
                                        this.abortSearch();
                                    } else {
                                        this.openSearchBox()
                                    }
                                }} >
                                <ISearch className={"padding-bottom-4px"} fill={`#128186`} width="15" />&nbsp;
                                {(searchActive) ? "Annulla filtro ricerca" : "Cerca"}
                            </button>
                        </div>

                    </div>
                </Col>
                <Col lg={2} className={"d-flex justify-content-end"}>
                    <CreateRelationshipForm
                        subject={subject}
                        subjectId={subjectId}
                        relationshipTypes={relationshipTypes}
                        relationshipLocations={relationshipLocations}
                        relationshipProvinces={relationshipProvinces}
                        currencies={currencies}
                        roles={roles}
                        communicationTypes={communicationTypes}
                        getRelationships={this.reloadRelationships}
                        {...this.props}
                    />
                </Col>
                {
                    this.state.showImportModal &&

                    <MultiImportModal
                        show={this.state.showImportModal}
                        onHide={() => this.setState({ showImportModal: false })}
                        components={[
                            () => <button className='btn btn-outline-primary btn-empty btn-lg me-2' onClick={() => {
                                this.setState({ showImportModal: false, showSubjectImportModal: true })
                            }}
                            >Soggetto da CORAtu (Export per CoraWeb)</button>
                            , () => <button className='btn btn-outline-primary btn-empty btn-lg me-2' onClick={() => {
                                this.setState({ showImportModal: false, showSubjectImportModal: true })
                            }}
                            >Rapporti da comunicazione TXT</button>
                            , () => <button className='btn btn-outline-primary btn-empty btn-lg me-2' onClick={() => {
                                this.setState({ showImportModal: false, showRelationshipImportModal: true })
                            }}
                            >Rapporti da file CSV</button>
                            , () => <button className='btn btn-outline-primary btn-empty btn-lg me-2' onClick={() => {
                                this.setState({ showImportModal: false, showImportBalanceModal: true })
                            }}
                            >SALDI da file CSV</button>
                            , () => <button className='btn btn-outline-primary btn-empty btn-lg me-2' onClick={() => {
                                this.setState({ showImportModal: false, showImportConsistencyModal: true })
                            }}
                            >Allineamento rapporti all'ultima fotografia elaborata</button>

                        ]}
                    />


                }
                {this.state.showSubjectImportModal &&

                    <ImportDataModal
                        show={this.state.showSubjectImportModal}
                        onSuccess={() => {
                            this.reloadRelationships();
                            this.setState({ showSubjectImportModal: false })
                        }}
                        onClose={() => { this.setState({ showSubjectImportModal: false }) }}
                        subjectId={subjectId}
                    />

                }
                {this.state.showImportConsistencyModal &&
                    <ImportConsitencyRelationshipsForm
                        show={this.state.showImportConsistencyModal}
                        onHide={() => this.setState({ showImportConsistencyModal: false })}
                        onSuccess={() => {
                            this.reloadRelationships()
                            this.setState({ showImportConsistencyModal: false })
                        }}
                    />
                }
                {this.state.showRelationshipImportModal &&
                    <CoraImportRelationsModal
                        show={this.state.showRelationshipImportModal}
                        onClose={() => this.setState({ showRelationshipImportModal: false })}
                        onSuccess={() => {
                            this.reloadRelationships()
                            this.setState({ showRelationshipImportModal: false })
                        }}
                        subject={subject}
                    />

                }
                {this.state.showImportBalanceModal &&
                    <YearlyBalanceImportModal
                        subjectId={this.props.subjectId}
                        onClose={() => {
                            this.setState({ showImportBalanceModal: false })
                            }
                        }
                        getYearlyBalance={() => { this.props.onImportedBalances()}}
                    />

                }

            </React.Fragment >
        )
    }


    renderHeadRelationships() {
        return (
            <React.Fragment>
                <div className='col w-5 text-center div-td'>
                    <input type="checkbox" checked={this.state.allCheck} onChange={this.handleAllCheckChange} />
                </div>
                <div className='col w-20 div-td'>Anagrafica <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("completeName")} /></div>
                <div className='col w-10 div-td'>Codice fiscale <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("fiscalCode")} /></div>
                <div className='col w-7 div-td'>Data Inizio <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("startDate")} /></div>
                <div className='col w-7 div-td'>Data Fine <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("endDate")} /></div>
                <div className='col w-12 div-td'>Rapporto <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("relType")} /></div>


                <div className='col w-33 div-td'>
                    {this.state.showIdentifiers ?
                        <>
                            <StarTooltip text="Fai click qui per visualizzare le note">
                                <span style={{ cursor: "pointer" }} onClick={() => { this.setState({ showIdentifiers: false }) }}>
                                    <u>Codice univoco</u>
                                </span>
                            </StarTooltip>
                            <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("identifier")} />
                        </> :
                        <>
                            <StarTooltip text="Fai click qui per visualizzare i codici univoci">
                                <span style={{ cursor: "pointer" }} onClick={() => { this.setState({ showIdentifiers: true }) }}>
                                    <u>Descrizione/Note</u>
                                </span>
                            </StarTooltip>
                            <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("comment")} />
                        </>
                    }
                </div>
                <div className='col w-10 div-td'>Da spedire/Errori <ISort width="10" className={"pointer float-end"} fill="#128186" onClick={() => this.sortBy("comType")} /></div>
            </React.Fragment>
        )
    }

    renderRowRelationships(subject, subjectId, relationships, relationshipTypes, relationshipLocations, relationshipProvinces, currencies, roles, communicationTypes) {
        if (this.state.loading) {
            return (<RowSpinner size={100} />)
        }
        return relationships.map((relationship, index) => {
            return (
                <RelationshipRow
                    onClone={(id) => this.setState({ clonedRelationshipId: id })}
                    isCloned={relationship.id === this.state.clonedRelationshipId}
                    ref={this.rowRefData}
                    subject={subject}
                    key={"relationShipRow_" + relationship.id}
                    subjectId={subjectId}
                    index={index}
                    relationship={relationship}
                    relationshipTypes={relationshipTypes}
                    relationshipLocations={relationshipLocations}
                    relationshipProvinces={relationshipProvinces}
                    currencies={currencies}
                    roles={roles}
                    communicationTypes={communicationTypes}
                    isChecked={this.state.checkedItems.get(relationship.id)}
                    handleCheckChange={this.handleCheckChange}
                    getRelationships={this.reloadRelationships}
                    showIdentifiers={this.state.showIdentifiers}
                />
            )

        });
    }

    render() {
        const { relationshipTypes, relationshipLocations, relationshipProvinces, currencies, roles, subject, communicationTypes } = this.props;
        let relationshipsData = this.state.relationships;
        let noResultsMessage = (this.state.relationships && this.state.relationships.length === 0) && "Nessun risultato trovato";
        return (
            <React.Fragment>

                <div className="row header-btn-relationship align-items-start pt-3" >
                    {this.renderHeaderButtons(subject, this.props.subjectId, relationshipTypes, relationshipLocations, relationshipProvinces, currencies, roles, communicationTypes)}
                    <Col>
                        <span style={{ color: "gray", fontSize: "0.7rem" }}>
                            Rapporti in lista: {this.state.relationships.length}
                        </span>
                    </Col>
                </div>
                {this.renderSearchBox()}
                <div className="text-start header-table">
                    {this.renderHeadRelationships()}
                </div>
                {noResultsMessage ?
                    <div className={"row"}><div className={"col-12 text-center mt-27 no-result"}>{noResultsMessage}</div></div>
                    :
                    this.renderRowRelationships(subject, this.props.subjectId, relationshipsData, relationshipTypes, relationshipLocations, relationshipProvinces, currencies, roles, communicationTypes)
                }
            </React.Fragment>
        )
    }

    renderSearchBox() {
        return <div id={"relationships-search-bar"} className={`${!this.state.searchIsOpen && 'hidden'}`}>
            <Row>
                <div className={"col-6 vcenter"}>
                    <div className="inputSelect">
                        <BwmSelect
                            options={this.state.searchOptions}
                            name='searchType'
                            label='Ricerca nel campo:'
                            className='form-control'
                            onChange={this.handlerChangeSearch}
                            value={this.props.filterParams.searchType} />
                    </div>
                </div>
                <div className={`col-5 vcenter `}>
                    <BwmInput name='searchTerms'
                        label={"Cerca"}
                        className='form-control'
                        onChange={event => this.dynamicSearch(event)}
                        placeholder={this.props.filterParams.searchPlaceholder}
                        value={this.props.filterParams.searchTerm} />
                </div>
            </Row>
            <Row className='me-3 ms-3'>
                <Col lg={6} className=' d-flex align-items-center'>
                    <button type="button" className="btn btn-outline-primary btn-empty" onClick={() => this.closeSearchBox()}>
                        Chiudi
                    </button>
                </Col>
                <Col lg={6} className=' d-flex align-items-center justify-content-end'>
                    <button type="button"
                        disabled={isEmpty(this.props.filterParams?.searchTerm)}
                        className="btn btn-new-rel" onClick={() => this.confirmSearch()}>
                        OK
                    </button>
                </Col>


            </Row>

        </div>;
    }
}

function mapStateToProps(state) {

    return {
        relationships: state.relationships.data,
        totalElements: state.relationships.total,
        subject: state.subject.data
    }
}

export default connect(mapStateToProps)(RelationshipsList);
