
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  Header,
  HeaderRow,
  HeaderCell,
  Body,
  Row,
  Cell,
} from '@table-library/react-table-library/table';

import {
  useRowSelect,
  SelectClickTypes
} from '@table-library/react-table-library/select';
import { useTheme } from '@table-library/react-table-library/theme';
import { DEFAULT_OPTIONS, getTheme } from '@table-library/react-table-library/material-ui';
import "./starTable.css"
import { HeaderFilterDropdown } from "./HeaderFilterDropdown";
import { TableUtils, baseSearchObject, fieldTypes } from "./TableUtils";
import NumberFormat from "react-number-format";
import { PageSelector } from "../searchComponents/PageSelector";
import { StarCell } from "./StarCell";
import { getTableStorage } from "./TableStorage";
import { DropdownMultipleSelect } from "../form/lists/DropdownMultipleSelect";
import { generateUniqueKey } from "../Utility";
import { TableFooter } from "./TableFooter";
import { StarCheckbox } from "../form/input-boxes/StarCheckbox";
import { ISortDown, ISortUp } from "../../../styles/icons";


/**
 * Componente multifunzione per liste di elementi.
 * 
 * per forzare un reload della lista(ad es in caso di salvataggi di nuove entità), ricalcolare la key del componente
 * @param {*} param0 
 * @returns 
 */
export function StarTable({
  headerColums = []//colonne header componibili tramite TableUtils.composeHeader()
  , rows = [] //righe componibili tramite TableUtils.composeRow(TableUtils.composeCell())
  , withCheckBoxes = false//indica se le righe sono selezionabili
  , onClickCheck = (ids) => { }//callback su click dei checkbox, passa la lista di id selezionati
  // , isLoading = false//indica se mostrare lo spinner
  , tableConfigurationKey = ""//chiave localStorage della configurazione della tabella, per determinare ad es. quali colonne mostrare
  , searchCallBack //callback di ricerca
  , onExecutedSearch //funzione eseguita a ricerca completata, fornisce il contenuto della ricerca
  , searchObjectPrototype: searchObject = {}//searchObject di base. Una copia viene conservata al montaggio per riportare la lista allo stato iniziale
  , setSearchObject = (searchObjet) => { } //setter per searchObject

}) {

  const tableConfiguration = tableConfigurationKey ? getTableStorage(tableConfigurationKey) : null;
  const [hiddenColumns, setHiddenColumns] = useState(tableConfiguration ? tableConfiguration?.getHiddenColumns() : []);
  const [columns, setColumns] = useState(headerColums);
  const [defaultSort, setDefaultSort] = useState("id");
  const [tableKey, setTableKey] = useState();
  const [showColumnFilter, setShowColumnFilter] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [initialSearchObject, setInitialSearchObject] = useState({});//oggetto di ricerca iniziale
  const [searchTerms, setSearchTerms] = useState({});//oggetto che contiene i termini di ricerca tramite header
  const [enableResetButton, setEnableResetButton] = useState(false);
  const [showCleanButton, setshowCleanButton] = useState(false);
  const [headerSizes, setHeaderSizes] = useState("");

  const timeout = useRef();
  const tableRef = useRef(null);


  //aggiunge righe per raggiungere l'altezza minima della tabella
  const prepareRows = () => {
    let rule = "";
    rows.forEach(() => {
      rule += "auto ";
    });
    const minimunRows = 15;

    for (let index = rows.length; index < minimunRows; index++) {
      rule += "1fr "

    }

    return rule;
  }

  const theme = useTheme([getTheme({
    ...DEFAULT_OPTIONS,
    striped: true
  }), {
    Cell: `height: 35px;
    padding: 5px!important;
    &:not(:last-of-type) {
      border-right: 1px solid #a0a8ae;
    }
    `,
    Row: ` &:last-child .td {}`,
    Header: `height: 50px;`,
    HeaderCell: `
    max-height:75px;
    padding: 5px!important;
    .table th {padding: 0 5px 0 5px};
    &:not(:last-of-type) {
      border-right: 1px solid #a0a8ae;
    }`,
    Table: `--data-table-library_grid-template-columns: ${headerSizes}; 
    min-height: 400px; 
    grid-template-rows: ${prepareRows()};
    grid-gap:0px;
    `
  }]);

  /**
   * utilizzato alla modifica dei campi in header
   * @param {*} columnName 
   * @param {*} query 
   */
  const onChangeSearch = (columnName, query) => {
    let newSearchObject = { ...searchObject };
    newSearchObject[columnName] = query;
    newSearchObject.page = 0;
    clearTimeout(timeout.current)
    setSearchObject(newSearchObject);
    setSearchTerms(TableUtils.getCleanSearchObject(newSearchObject));
    timeout.current = setTimeout(() => {
      executeSearch(newSearchObject)
    }, 500)

  }

  const onChangePage = (newSearchObject) => {
    executeSearch(newSearchObject);
    setSearchObject(newSearchObject);
  }

  //esegue ricerca iniziale, e conserva il searchObject iniziale
  useEffect(() => {
    setInitialSearchObject(searchObject);

    if (tableConfiguration) {
/*       tableConfiguration.setSearchObjectIfNotPresent(searchObject);
      searchObject = tableConfiguration.getSearchObject() */
      setColumns(TableUtils.updateHeadersOrder(columns, tableConfiguration.getColumnOrder()));

    }
    if (tableConfiguration?.getColumnSizes()) {
      setHeaderSizes(tableConfiguration.getColumnSizes());
    } else {

      setHeaderSizes(TableUtils.prepareHeaderSizes(
        columns,
        hiddenColumns,
        withCheckBoxes,
        tableConfiguration
      ));
    }
    executeSearch(searchObject);
    setSearchObject(searchObject);
    setSearchTerms(searchObject)
    setDefaultSort(searchObject.sortField);

    setTableKey(generateUniqueKey(tableConfigurationKey));



    //eventi di controllo per funzioni alternative
    const handleKeyDown = (event) => {
      if (event.altKey && event.ctrlKey) {
        setshowCleanButton(true);
      }
    };
    const handleKeyUp = (event) => {
      if (!event.altKey || !event.ctrlKey) {
        setshowCleanButton(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    //cleanup degli eventi
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);


  //determina se ci sono termini di ricerca da resettare
  useEffect(() => {
    let canBeReset = false;

    for (const [key, value] of Object.entries(searchTerms)) {
      if (!canBeReset) {
        const searchObjectKey = initialSearchObject[key];
        canBeReset = searchObjectKey !== value;
        //due array vuoti sono sempre diversi, inoltre è necessario verificarne gli elementi
        if (canBeReset
          && Array.isArray(value)
          && Array.isArray(searchObjectKey)
          && value.length === searchObjectKey.length) {
          if (value.length === 0) {
            canBeReset = false;
          } else {
            canBeReset = !searchObjectKey.every((element, index) => element === value[index]);
          }

        }
      }
    }
    setEnableResetButton(canBeReset);
  }, [searchObject])





  const executeSearch = (searchObject) => {
   
    searchCallBack &&  searchCallBack(searchObject).then((res) => {
     /*  if (tableConfiguration) {
        tableConfiguration.setSearchObject(searchObject);
      } */
      onExecutedSearch(res?.content)
      setTotalPages(res?.totalPages)
    })
      .then(() => { });
  }

  const renderColumnToggle = () => {
    return (
      <>
        <button
          className="btn btn-primary btn-new-rel"
          onClick={() => setShowColumnFilter(!showColumnFilter)}
        >
          Configura Colonne
        </button >

        <HeaderFilterDropdown
          headers={columns}
          hiddenColumnsProps={hiddenColumns}
          show={showColumnFilter}
          resultsPerPage={searchObject.pageSize}
          onHide={(hiddenColumns, orderedColumns, selectedResultsPerPage) => {
            if (tableConfiguration) {
              tableConfiguration.setHiddenColumns(hiddenColumns);
              tableConfiguration.setColumnOrder(orderedColumns);
            }
            setColumns(orderedColumns)
            setHiddenColumns(hiddenColumns)
            setShowColumnFilter(false);
            setHeaderSizes(TableUtils.prepareHeaderSizes(
              orderedColumns,
              hiddenColumns,
              withCheckBoxes,
              tableConfiguration
            ));
            onChangePage({ ...searchObject, pageSize: selectedResultsPerPage })
            //forzo un rerender per ricalcolare le dimensioni delle colonne
            setTableKey(generateUniqueKey(tableConfigurationKey));
          }}
        />
      </>
    )
  }



  const data =  { nodes: rows };


  const checkableDataRows = { nodes: data?.nodes.filter(node => node.checkable === true) };
  const select = useRowSelect(
    checkableDataRows,
    {
      onChange: (action, state) => {
        console.log(action, state)
        onClickCheck(state?.ids)
      },
    },
    { clickType: SelectClickTypes.ButtonClick }
  )



  const resize = { minWidth: 100, resizerHighlight: "#a6a6a6", resizerWidth: 5 };

  const composeCell = (rowId, data, index, columnIndex) => {
    return <StarCell key={`starcell-${rowId}-${index} `} rowId={rowId} data={data}
      index={index} headerColums={columns}
      hiddenColumns={hiddenColumns} columnIndex={columnIndex}/>
  }

  const handleLayoutChange = (widths) => {
    if (tableConfiguration) {
      const regex = /minmax\([^)]+\)/g;
      let minMaxedWidths = "";
      widths.match(regex).forEach((w, index) => {
        if (index === 0 && withCheckBoxes) {
          minMaxedWidths += `${w} `;
        } else {
          let trimmed = w.trim().replace("minmax(",'')
          let min = trimmed.substring(0, trimmed.lastIndexOf(','))
          let max = trimmed.substring(trimmed.lastIndexOf(min)+min+2,trimmed.length)
          max = max.substring(0, max.lastIndexOf(')'))

          minMaxedWidths += ` minmax(${min}, ${max || '1fr'}) `
        }
      })
      tableConfiguration.setColumnSizes(minMaxedWidths);
    }
  };

  const renderSortButtons = (searchObject, h, defaultSort) => {
    const size = 17;
    return <span>
      {searchObject.sortField === h.fieldName &&
        <>
          {searchObject.sortDirection === "DESC" ?

            <ISortDown width={size} className={"pointer float-end mb-1"}
              onClick={() => sort(searchObject, h, defaultSort)} />

            :
            <ISortUp width={size} className={"pointer float-end mb-1"}
              onClick={() => sort(searchObject, h, defaultSort)} />
          }
        </>}

    </span>;
  }

  const sort = (searchObject, h, defaultSort) => {

    // l'utente clicca un campo diverso da quello già ordinato
    if (searchObject.sortField !== h.fieldName) {
      onChangePage({ ...searchObject, sortField: h.fieldName, sortDirection: "ASC" })
    } else {
      if (searchObject.sortDirection === "DESC") {
        onChangePage({ ...searchObject, sortField: defaultSort, sortDirection: "DESC" })
      } else {
        onChangePage({ ...searchObject, sortField: h.fieldName, sortDirection: "DESC" });
      }
    }


  }

  return (
    <>
      <div className=" d-flex flex-column m-auto vh-75">

        <div className="star-table-container" >

          <Table
            key={tableKey}
            theme={theme}
            data={data}
            select={select}
            ref={tableRef}
            layout={{
              custom: true,
              fixedHeader: true,
              onLayoutChange: handleLayoutChange

            }} >
            {(tableList) => (
              <>
                <Header >
                  <HeaderRow >
                    {withCheckBoxes &&
                      <HeaderCell
                        resize={resize}
                        pinLeft
                        className="checkbox-column table-header">
                        <StarCheckbox
                          checked={select?.state?.all}
                          indeterminate={!select?.state?.all && !select?.state?.none}
                          onChange={select?.fns?.onToggleAll}
                          ariaLabel={"Seleziona tutti i visibili"}
                        />

                      </HeaderCell>

                    }
                    {columns.map((h, index) => {
                      return (
                        <HeaderCell
                          key={`header - ${index} `}
                          className={"table-header bg-blue"}
                          resize={resize}
                          hide={TableUtils.isHiddenColumn(hiddenColumns, h)}
                          pinRight={h.pinRight }
                          pinLeft={h.pinLeft }
                          
                        >

                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-center w-100">
                              {h.sortable ?
                                <span onClick={() => sort(searchObject, h, defaultSort)}
                                  style={{ cursor: "pointer" }}>
                                  {h.displayedName}
                                </span>
                                :
                                <span>
                                  {h.displayedName}
                                </span>
                              }

                              {h.searchable &&
                                <TableSearchField
                                  type={h.type}
                                  fieldName={h.fieldName}
                                  onChange={(value) => onChangeSearch(h.fieldName, value)}
                                  options={h.options}
                                  value={searchObject[h.fieldName]}
                                />}
                            </div>
                            <div className="align-self-end">
                              {h.sortable &&
                                renderSortButtons(searchObject, h, defaultSort)
                              }
                            </div>
                          </div>

                        </HeaderCell>


                      )

                    })}

                  </HeaderRow>
                </Header>
                <Body>
                  {
                    tableList.map((row) => {
                      const rowData = row.cellData;
                      const id = row.id;
                      return <Row
                        key={"star-row-"+id}
                        item={rowData}
                        className={row.additionalClass} >
                        {/*disegno la checkbox solo se prevista*/}
                        {withCheckBoxes &&
                          row.checkable &&
                          <Cell pinLeft item={row} className="checkbox-column">
                            <StarCheckbox
                              checked={select.state.ids.includes(id)}
                              onChange={() => {
                                select.fns.onToggleById(id)
                              }}
                              ariaLabel={"Seleziona elemento"}
                            />
                          </Cell>
                        }
                        {
                          withCheckBoxes &&
                          !row.checkable &&
                          <Cell pinLeft item={row} className="checkbox-column" />
                        }
                        {
                          //per preservare l'ordine delle celle e delle colonne, viene eseguito un doppio ciclo
                          columns.map((column, columnIndex) => {
                            return Object.entries(rowData).map((e, index) => {
                              if (e[1]?.fieldName === column.fieldName) {
                                return composeCell(id, e, index, columnIndex)
                              }
                            })
                          })
                        }
                      </Row>
                    })
                  }

                </Body>

              </>
            )}
          </Table>
          <TableFooter
            PageSelectorComponent={() => {
              return <PageSelector
                pageNumber={searchObject.page}
                resultsPerPage={searchObject.pageSize}
                totalPages={totalPages}
                onUpdateNumberOfResults={(val) => onChangePage({ ...searchObject, pageSize: val })}
                onClickBack={() => onChangePage({ ...searchObject, page: searchObject.page - 1 })}
                onClickForward={() => onChangePage({ ...searchObject, page: searchObject.page + 1 })}
              />
            }}
            CleanButtonComponent={() => {
              return <CleanButton
                showCleanButton={showCleanButton}
                enableResetButton={enableResetButton}
                onCleanCache={() => {
                  tableConfiguration.cleanSearchObject();
                  window.location.reload(false);
                }}
                onCleanSearch={() => {
                  const resetSearchObject = { ...baseSearchObject, ...initialSearchObject, pageSize: searchObject.pageSize };
                  setSearchTerms({});
                  onChangePage(resetSearchObject);
                }}

              />
            }}
            renderConfigurationBox={renderColumnToggle}
            tableRef={tableRef}
          />
        </div >


      </div >

    </>
  );
}


function CleanButton({ showCleanButton = false
  , enableResetButton = false
  , onCleanCache = () => { }
  , onCleanSearch = () => { }
}) {
  return <>
    {showCleanButton &&

      <button
        className="btn btn-primary btn-new-rel mb-2"
        onClick={onCleanCache}>
        Pulisci cache
      </button>
/* 
      :
      <button
        className="btn btn-primary btn-new-rel mb-2"
        disabled={!enableResetButton}
        onClick={onCleanSearch}
      >Annulla ricerca
      </button> */
    }


  </>
}



function TableSearchField({
  type = fieldTypes.TEXT
  , onChange = () => { }
  , options = [{ id: null, description: "" }]
  , value = ""
} = {}) {


  if ([fieldTypes.DATE, fieldTypes.TEXT, fieldTypes.NUMBER].includes(type)) {
    return <input
      type={[fieldTypes.DATE, fieldTypes.TEXT].includes(type) ? fieldTypes.TEXT : fieldTypes.NUMBER}
      onChange={(e) => onChange(e.target.value)}
      className="cell-input"
      placeholder="Cerca..."
      value={value || ""}
    />
  } else if (fieldTypes.CURRENCY === type) {
    return <NumberFormat
      thousandSeparator={'.'}
      decimalSeparator={','}
      decimalScale={0}
      fixedDecimalScale={true}
      className="cell-input"
      inputMode="numeric"
      onValueChange={value => onChange(value.floatValue)}
      value={value}
    />
  } else if (fieldTypes.SELECTION === type) {
    return <DropdownMultipleSelect
      options={options}
      onSelectionChange={(options) => onChange(options.map(o => o.id))}
      value={options.filter(o => {
        if (value && value.length > 0) {
          return value.includes(o.id);
        }
        return false;
      })}
    />
  }
}

